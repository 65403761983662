import {  createActions } from 'redux-actions';

const actions = createActions({
    SET_COMPANY: (company) => ({ company })
});

const { setCompany } = actions;

export default {
    setCompany
};