import React, {useState,useEffect} from 'react';
import moment from 'moment'
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  colors
} from "variables/charts.js";

import { useApi, useToast } from "../../../hooks";
import Header from 'components/Headers/Header';


const Charts = () => {

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const options= {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: colors.gray[900],
            zeroLineColor: colors.gray[900]
          },
          ticks: {
            callback: function(value) {
              if (!(value % 10)) {
                return "$" + value ;
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: function(item, data) {
          var label = data.datasets[item.datasetIndex].label || "";
          var yLabel = item.yLabel;
          var content = "";

          if (data.datasets.length > 1) {
            content += label;
          }

          content += "$" + yLabel ;
          return content;
        }
      }
    }
  }

  var date = moment().format('YYYY-MM-DD'); 
  var initDate = moment().startOf('month').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(initDate)
  const [endDate, setEndDate] = useState(date)
  const [users, setUsers] = useState([])
  const [chartSaleData, setChartSaleData] = useState({})
  const toast = useToast();
  const { getUsers, getChartSale } = useApi();

  useEffect(()=>{
    //getUserList();
    getReporChartSale(startDate, endDate)
  }, [])

  const getReporChartSale = async () =>{
    
      const { response, error } = await getChartSale(startDate, endDate);
      if (response) {
          const { content, message } = response.data;
          setCharData(content)
      } else if (error) {
          error.message && toast.error(error.message);
      }
  
  }

  const setCharData = (content) =>{
    setChartSaleData(canvas => {
      return {
        labels: content.dates,
        datasets: [
          {
            label: "Saldo",
            data: content.totals
          }
        ]
      };
    })
  }

  const getUserList = async () =>{
    
    const { response, error } = await getUsers();
    if (response) {
    const { content, message } = response.data;
    setUsers(content);
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

    return (
        <>
          <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
              <Row>
                <Col className="mb-5 mb-xl-0" xl="12">
                  <Card className="bg-gradient-default shadow">
                    <CardHeader className="bg-transparent">
                      <Row className="align-items-center">
                        <div className="col-4">
                          <h6 className="text-uppercase text-light ls-1 mb-1">
                            Resumen
                          </h6>
                          <h2 className="text-white mb-0">Balances Por día</h2>
                        </div>
                        <div className="col-8">
                        <Row>
                                    <Col lg="5">
                                            <FormGroup>
                                                <label
                                                className="form-control-label text-white"
                                                htmlFor="startDate" >
                                                    Fecha Inicio
                                                </label>
                                                <Input
                                                className="form-control-alternative"
                                                id="startDate"
                                                name="startDate"
                                                value={startDate}
                                                onChange={e=>{setStartDate(e.target.value)}}
                                                type="date" >
                                                </Input>
                                                        
                                            </FormGroup>
                                        </Col>
                                        <Col lg="5">
                                            <FormGroup>
                                                <label
                                                className="form-control-label text-white"
                                                htmlFor="endDate">
                                                    Fecha Fin
                                                </label>
                                                <Input 
                                                    name = "endDate"
                                                    className="form-control-alternative"
                                                    id="endDate"
                                                    value={endDate}
                                                    onChange={e=>{setEndDate(e.target.value)}}
                                                    type="date" />
                                            </FormGroup>
                                        </Col> 
                                        <Col lg="2">
                                            <Button Style="top:10px" className="my-4" color="primary" type="submit" onClick={()=>{getReporChartSale()}}>
                                                Consultar
                                            </Button>
                                        </Col>                                   
                                    </Row>
                        </div>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {/* Chart */}
                      <div className="chart">
                        <Line
                          data={chartSaleData}
                          options={options}
                          getDatasetAtEvent={e => console.log(e)}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>

            
            {/* <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Total orders</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  }
                  <div className="chart">
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col> */}
           
        </>
    );
};

export default Charts;