import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProSidebar, SidebarHeader, SidebarContent, Menu, MenuItem } from 'react-pro-sidebar';
import {headerContainer, logoNavbar, navbarToggleButton} from './style';
import { getUser } from '../../../selectors/user';
import logo from '../../../assets/img/brand/logo2.png';

import 'react-pro-sidebar/dist/css/styles.css';
import './style.css';

function NewSidebar({ collapsed, handleCollapse, routes }) {

    const user = useSelector(state => getUser(state));

    const createLinks = (array) => array.map((prop, key) => (
        prop.layout === "/admin" && prop.users.includes(user.rol_id)
            ? <MenuItem
                key={key}
                icon={<i className={prop.icon} />}
            >
                <Link to={prop.layout + prop.path}>{prop.name}</Link>
            </MenuItem>
            : <></>
    ));

    const renderLinks = useMemo(() => createLinks(routes), [routes]);

    return(
        <>
            <ProSidebar collapsed={collapsed}>
                <SidebarHeader>
                    <div style={{ ...headerContainer, justifyContent: collapsed ? 'center' : 'space-around' }}>
                        <img src={logo} alt="..." style={{ ...logoNavbar, display: collapsed ? 'none' : 'block' }} />
                        <button style={navbarToggleButton} onClick={handleCollapse}>
                            <i className="ni ni-align-left-2 text-secondary" />
                        </button>
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="circle">
                        {renderLinks}
                    </Menu>
                </SidebarContent>
            </ProSidebar>
        </>
    );
}

export default NewSidebar;