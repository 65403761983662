/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Badge,
  Table
} from "reactstrap";
import { useForm } from "react-hook-form";
// core components
import Header from "components/Headers/Header.js";
import { useApi, useToast } from "../../hooks";
import PaginationComponent from "components/Pagination";

const Company = () => {

  const { register, handleSubmit, errors, setValue, reset  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  const [companies, setCompanies] = useState([])
  const [owners, setOwners] = useState([])
  const [update, setUpdate] = useState(false)
  const [pagination, setPagination] = useState({
    perPage: 10,
    page:1,
    pages:0
  });

  const toast = useToast();
  const { storeCompany, getCompaniesPagination, changeCompanyStatus, deleteCompany, getCompaniesByOwner, getOwners } = useApi();

  const getCompanyListPage = async () =>{
   
    const { response, error } = await getCompaniesByOwner();
    if (response) {
      const { content, message } = response.data;
      setCompanies(content);
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getOwnerList = async () =>{
   
    const { response, error } = await getOwners();
    if (response) {
      const { content, message } = response.data;
      setOwners(content);
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }
  
  const saveCompany = async data => {
    if(!update){
      delete data.id;
    }
    //console.log(data);
    const { response, error } = await storeCompany(data);
    if (response) {
      const { content, message } = response.data;
      toast.success(message);
      
      getCompanyListPage(pagination)
      
      setUpdate(false)
      reset();
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const renderOptions = (list) => {
    return list.map((prop,key) => {
      return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
    });
  }

  const changeStatusCompany = async data => {
    
    const object = {
      id: data.id,
      status: data.status
    }
    const { response, error } = await changeCompanyStatus(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      
      getCompanyListPage(pagination)
      
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const deleteCompanyRow = async data => {
    
    const object = {
      id: data.id
    }
    const { response, error } = await deleteCompany(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      getCompanyListPage(pagination)
      
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const fillShop = (company) =>{
    
    setValue([
      { id : company.id },
      { ownerId : company.owner_id },
      { identification : company.identification },
      { name : company.name }
    ])
    setUpdate(true)

  }

  const renderTable = (shops) => {
        let data = shops.map((prop, key) => {
            return  (
                  <tr key={prop.id}>
                    <th scope="row">{prop.identification}</th>
                    <td>{prop.name}</td>
                    <td>{prop.owner.name}</td>
                    <td>
                      {
                        prop.status == 1? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Activo
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          Inactivo
                        </Badge>
                      }
                     
                    </td>
                  <td className="text-left">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        href="#"
                        role="button"
                        size="sm"
                        color=""
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          href="#"
                          onClick={() => fillShop(prop)}
                        >
                          Actualizar
                        </DropdownItem>
                        <DropdownItem
                          href="#"
                          onClick={() => changeStatusCompany(prop)}
                        >
                          Cambiar Estado
                        </DropdownItem>
                        <DropdownItem
                          href="#"
                          onClick={()=> deleteCompanyRow(prop)}
                        >
                          Eliminar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                
                </tr>
                
                )
        })
        return data;
  }

  const onPagination = (pagination)=>{
    getCompanyListPage(pagination);
  }
   
  useEffect(()=>{
    getCompanyListPage(pagination);
    getOwnerList()
  }, [])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="6">
          <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lista Oficinas</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referencia</th>
                    <th scope="col">Nombres</th>
                    <th scope="col">Empresa</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.length > 0 && renderTable(companies)}
                </tbody>
              </Table>
            </Card>
          
          </Col>
          <Col className="order-xl-1" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Crear Oficina</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(saveCompany)}>
                  <h6 className="heading-small text-muted mb-4">
                    Información de la Oficina
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name"
                          >
                            Nombre de la Oficina
                          </label>
                          <Input 
                              name = "name"
                              className="form-control-alternative"
                              id="name"
                              placeholder="Nombre de la Oficina"
                              type="text"
                              innerRef={register({ required: true})} 
                          />
                          {!!errors?.name?.types?.required && <FormText>Campo requerido.</FormText>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="identification"
                          >
                            Referencia 
                          </label>
                          <Input 
                              name = "identification"
                              className="form-control-alternative"
                              id="identification"
                              placeholder="Referencia"
                              type="text"
                              innerRef={register({ required: true, pattern: /\d+/gi})} 
                          />
                          {!!errors?.identification?.types?.required && <FormText>Campo requerido.</FormText>}
                          {!!errors?.identification?.types?.pattern && <FormText>Escribe solo números.</FormText>}
                        </FormGroup>
                      </Col>
                   
                    </Row>
                    <Row>  
                        <Col lg="6">
                          <Input
                              className="form-control-alternative"
                              hidden
                              id="id"
                              name="id"
                              type="number"
                              innerRef={register} 
                            />
                        </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="ownerId"
                          >
                            Empresa
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="ownerId"
                            name="ownerId"
                            placeholder="Seleccionar una opción"
                            type="select"
                            innerRef={register({ required: true })} >
                              <option></option>
                              {renderOptions(owners)}
                          </Input>
                          {!!errors?.ownerId?.types?.required && <FormText>Campo requerido.</FormText>}
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <div className="text-center">
                          {
                            !update ? 
                            <Button className="my-4" color="primary" type="submit" >
                                Guardar
                            </Button>
                            :
                            <>
                              <Button className="my-4" color="danger" type="button" onClick={()=>{reset(); setUpdate(false)}} >
                                  Cancelar
                              </Button>
                              <Button className="my-4" color="info" type="submit" >
                                  Actualizar datos
                              </Button>
                            </>
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Company;


