/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import Charts from "./Charts";
import Sale from "./Sale";
import General from "./General";

class Dashboard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        rSelected: 1
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  setTab(tab){
      this.setState({
        rSelected:tab
      })
  }
  

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
            <Row>
                <Col className="mb-5 mb-xl-0" xl="3">
                    <Card className="card-stats" onClick={()=> this.setTab(1)}>
                        <CardBody>
                            <Row>
                                <Col xl="10">
                                    <h5 className="text-uppercase text-muted mb-0 card-title">Resumen</h5>
                                    <span className="h2 font-weight-bold mb-0">Analítica</span>
                                </Col>
                                <Col xl="2">
                                    <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                        <i className="ni ni-chart-pie-35"></i>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mb-5 mb-xl-0" xl="3">
                    <Card className="card-stats" onClick={()=> this.setTab(2)}>
                        <CardBody>
                            <Row>
                                <Col xl="10">
                                    <h5 className="text-uppercase text-muted mb-0 card-title">Reporte</h5>
                                    <span className="h2 font-weight-bold mb-0">Cuadre por Día</span>
                                </Col>
                                <Col xl="2">
                                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                        <i className="ni ni-chart-bar-32"></i>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mb-5 mb-xl-0" xl="3">
                    <Card className="card-stats" onClick={()=> this.setTab(3)}>
                        <CardBody>
                            <Row>
                                <Col xl="10">
                                    <h5 className="text-uppercase text-muted mb-0 card-title">Reporte</h5>
                                    <span className="h2 font-weight-bold mb-0">General</span>
                                </Col>
                                <Col xl="2">
                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                        <i className="ni ni-world-2"></i>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mb-5 mb-xl-0" xl="3">
                    <Card className="card-stats" onClick={()=> this.setTab(4)}>
                        <CardBody>
                            <Row>
                                <Col xl="10">
                                    <h5 className="text-uppercase text-muted mb-0 card-title">Reporte</h5>
                                    <span className="h2 font-weight-bold mb-0">Prestamos</span>
                                </Col>
                                <Col xl="2">
                                    <div className="icon icon-shape bg-gradient-gray text-white rounded-circle shadow">
                                        <i className="ni ni-credit-card"></i>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                
            </Row>
            <br/>
            <Row>
                {
                    this.state.rSelected ===1 ?
                    <> 
                        <Charts />
                    </> :  this.state.rSelected ===2 ?
                    <>
                        <Sale />
                    </> :  this.state.rSelected ===3 ?
                    <>
                        <General />
                    </> :  this.state.rSelected ===4 ?
                    <>
                    
                    </> : <></>
                }
                
            </Row>
            

          
        </Container>
      </>
    );
  }
}

export default Dashboard;
