import React,{useState,useEffect, useRef, useMemo} from 'react';
import moment from 'moment'
import { CURRENCY } from 'variables/app';
import { useReactToPrint } from 'react-to-print';

// reactstrap components
import {
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Card,
    Modal,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Table,
    CardTitle
  } from "reactstrap";
  import Header from 'components/Headers/Header';

  import { useApi, useToast } from "../../hooks";

  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../selectors/jobs";

  import BadQuotes from "./BadQuotes";  
  import Payments from "./Payments";  
  
const FinalSale = () => {

    const company = useSelector(state => state.company.company)
    const rol = useSelector(state => state.auth.user.rol_id)
    var date = moment().format('YYYY-MM-DD'); 
    const [startDate, setStartDate] = useState(date)
    const [userReport, setUserReport] = useState(null)
    const [reportData, setReportData] = useState({
        newLoans:[],
        oldLoans:[],
        noUpdate:[],
        payments:[],
        totalActive:0,
        totalSale:0,
        total:0,
        totalExpenses:0
    })

    const [papeleriaReal, setPapeleriaReal] = useState(0)
    const [entrega, setEntrega] = useState(0)
    const [debeEntregar, setDebeEntregar] = useState(0)

    const [supVerifica, setSupVerifica] = useState('')
    const [supRevisa, setSupRevisa] = useState('')
    const [inject, setInject] = useState(0)
    
    const [papeleria, setPapeleria] = useState(0)
    const [papeleriaEntra, setPapeleriaEntra] = useState(0)
    const [barba, setBarba] = useState(0)
    const [base, setBase] = useState(0)
    const [pago, setPago] = useState(0)
    const [barbaEntra, setBarbaEntra] = useState(0)
    const [abonos, setAbonos] = useState(0)
    const [trabajadorEntra, setTrabajadorEntra] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpenPayment, setModalOpenPayment] = useState(false)
    const [faltantePapeleria, setFaltantePapeleria] = useState(0)

    const [pleno, setPleno] = useState(0)

    const [unionLoans, setUnionLoans ] = useState({}) 

    const [totalNew, setTotalNew] = useState(0)
    const [totalOld, setTotalOld] = useState(0)

    const [users, setUsers] = useState([])
    const [supervisors, setSupervisors] = useState([])

    const [beardJob, setBeardJob] = useState(0)
    const [beardPp, setBeardPp] = useState(0)
    const [beardBase, setBeardBase] = useState(0)
    const [additional, setAdditional] = useState(0)
    const [baseSobrante, setBaseSobrante] = useState(0)
    const [detail, setDetail] = useState("")

    const toast = useToast();
    const { getUsers, getReportLoanSale, storeFinalSale, getSupervisors, deleteFinalSale } = useApi();

    useEffect(()=>{
        getUserList()
        getSupervisorList()
    }, [])

    const closeModal = ()=>{
        calculateBadQuotes()
        setModalOpen(false)
    }

    const openModal = () =>{
        setModalOpen(true)
    }

    const closeModalPayment = ()=>{
        setModalOpenPayment(false)
    }

    const openModalPayment = () =>{
        setModalOpenPayment(true)
    }

    const calculateBadQuotes = () =>{

    }


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const tLoans = useMemo(() => {
        
        let totalOld=0
        
        reportData.noUpdate.forEach(prop => {
            if(!unionLoans[prop.id]){
                totalOld+=parseInt(prop.quote_day)
            }  
        
       })
       
       return totalOld + abonos*1000

    } , [reportData.noUpdate, unionLoans, abonos])

    const payment = useMemo(() => {
        
        let totalPayment=0
        reportData.payments.forEach(prop => {
            
            totalPayment+=parseInt(prop.value)
            
        })
       return totalPayment > 0 ? totalPayment/1000 : pago
    } , [reportData, pago])

    const processLoans = () => {
        let loans = []
        reportData.newLoans.forEach(item => {
            let sale = 0
            let union_id = []
            let id = null
            if(item.union){
                item.union.forEach(un => {
                    union_id.push(un.id)
                });
                sale = item.OldLoan ? parseInt(item.OldLoan.quote_day) : 0
                item.union.forEach(lc => {
                    sale += parseInt(lc.quote_day)
                });
                
            }else{
                sale = item.OldLoan ? parseInt(item.OldLoan.quote_day) : 0
            }

            if(item.finalSale){
                id = item.finalSale.id
            }

            const barba = parseInt(item.value)-(sale+parseInt(item.ingresa)+parseInt(item.entrega)+parseInt(item.papeleria)+parseInt(item.debe)-parseInt(item.dio)) 
            loans.push({
                id, 
                loan_id: item.id,
                entrega: item.entrega,
                debe: item.debe,
                dio: item.dio,
                barba,
                union_id
            })
            
        })
        return loans
    }

    const saveSale = async () => {

        const loans = processLoans()
        const object = {
            userId: userReport,
            date: startDate,
            stationery: papeleria,
            realStationery: papeleria - papeleriaReal,
            stationeryEntry: papeleriaEntra,
            beard: barba,
            beardEntry: barbaEntra,
            jobDiscountEntry: trabajadorEntra,
            jobEntry: entrega,
            base,
            pago: payment,
            pagos: reportData.payments,
            loans,
            abonos,
            supVerifica,
            supRevisa,
            inject,
            beardJob,
            additional,
            beardPp,
            beardBase,
            baseSobrante,
            detail
        }

        const { response, error } = await storeFinalSale(object);
        if (response) {
          const { content, message } = response.data;
          toast.success(message);
        } else if (error) {
            if(error.response.status == 400){
                error.message && toast.error(error.response.data.message);
              }else{
                error.message && toast.error(error.message);
              } 
        } 
    }

    const removeSale = async () => {

        const object = {
            user_id: userReport,
            date: startDate
        }

        const { response, error } = await deleteFinalSale(object);
        if (response) {
          const { content, message } = response.data;
          toast.success(message);
        } else if (error) {
            if(error.response.status == 400){
                error.message && toast.error(error.response.data.message);
              }else{
                error.message && toast.error(error.message);
              } 
        } 
    }

    const getUserReport = userId =>{
        let user = null
        for (let index = 0; index < users.length; index++) {
            if(users[index].id==userId){
                user =users[index]
            }
            
        }
        return user;
    }
    
    const getReportLoanRange = async () =>{
        if(!userReport)
            return toast.error("Selecciona un usuario");

        const { response, error } = await getReportLoanSale(startDate, userReport);
        if (response) {
            const { content, message } = response.data;
            if(content && content.finalSale != null){
                //setUnions(content)
                setUnionLoans(content.unions)
                setPapeleria(parseInt(content.finalSale.stationery)/1000)
                setPapeleriaReal((parseInt(content.finalSale.stationery)-parseInt(content.finalSale.real_stationery))/1000)
                setPapeleriaEntra(parseInt(content.finalSale.stationery_entry)/1000)
                setBarbaEntra(parseInt(content.finalSale.beard_entry)/1000)
                setTrabajadorEntra(parseInt(content.finalSale.job_discount_entry)/1000)
                setEntrega(parseInt(content.finalSale.job_entry)/1000)
                setBase(parseInt(content.finalSale.base)/1000)
                setPago(parseInt(content.finalSale.pago)/1000)
                setAbonos(parseInt(content.finalSale.abonos)/1000)
                setSupRevisa(content.finalSale.sup_revisa)
                setSupVerifica(content.finalSale.sup_verifica)
                setInject(parseInt(content.finalSale.inject)/1000)
                setBeardJob(parseInt(content.finalSale.beard_job)/1000)
                setAdditional(parseInt(content.finalSale.additional)/1000)
                setBeardPp(parseInt(content.finalSale.beard_pp)/1000)
                setBeardBase(parseInt(content.finalSale.beard_base)/1000)
                setBaseSobrante(parseInt(content.finalSale.base_sobrante)/1000)
                setDetail(content.finalSale.base_detail)
                //setTotalOldAndNew()
                //getTotalOld(content.noUpdate)
            }else{

            }
            setReportData(content)
            if(content.newLoans.length <= 0){
                setPleno(parseInt(content.totalActive))
                setDebeEntregar(parseInt(content.totalActive) + getTotalOldReturn(content.noUpdate) - parseInt(content.totalExpenses) )
            }
            
            
        } else if (error) {
            error.message && toast.error(error.message);
        }
    
    }

    const isUnion = (customer_id) => {
        return unionLoans[customer_id]
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(getFilterUsers(content, company.id));
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const getSupervisorList = async () =>{
    
        const { response, error } = await getSupervisors();
        if (response) {
            const { content, message } = response.data;
            setSupervisors(content);
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    const updateDio = (value, customer_id) => {
        let report = {...reportData}
        let newLoans = report.newLoans
        newLoans.map((prop, key) =>{
            if(prop.customer_id == customer_id){
                prop.dio = value*1000
            }
        })

        report.newLoans = newLoans
        setReportData(report)

    }

    const updateEntrega = (value, customer_id) => {
        let report = {...reportData}
        let newLoans = report.newLoans
        newLoans.map((prop, key) =>{
            if(prop.customer_id == customer_id){
                prop.entrega = value*1000
            }
        })

        report.newLoans = newLoans
        setReportData(report)
    }

    const updateDebe = (value, customer_id) => {
        let report = {...reportData}
        let newLoans = report.newLoans
        newLoans.map((prop, key) =>{
            if(prop.customer_id == customer_id){
                prop.debe = value*1000
            }
        })

        report.newLoans = newLoans
        setReportData(report)
    }

    const updatePay = (value, id) => {
        let report = {...reportData}
        let payments = report.payments
        payments.map((prop, key) =>{
            if(prop.id == id){
                prop.value = value*1000
            }
        })

        report.payments = payments
        setReportData(report)
    }

    

    const updateUnion = (loan, customer_id) => {
        let report = {...reportData}
        let newLoans = report.newLoans
        newLoans.map((prop, key) =>{
            if(prop.customer_id == customer_id){
                if(prop.hasOwnProperty("union")){
                    prop.union.push(loan)
                }else{
                    prop.union = [loan]
                }  
            }
        })

        report.newLoans = newLoans
        
        setReportData(report)
        let unions = {...unionLoans}
        unions[loan.id] = loan
        setUnionLoans(unions)
        //getTotalOld(reportData.noUpdate)
    }

    const deleteUnion = (customer_id, union) => {
        let report = {...reportData}
        let newLoans = report.newLoans
        newLoans.map((prop, key) =>{
            if(prop.customer_id == customer_id){
                prop.union = []
            }
        })

        report.newLoans = newLoans
        setReportData(report)

        let unions = {...unionLoans}
        console.log("deleteUn", union, unions)
        union.forEach(un => {
            delete unions[un.id]
        });

        setUnionLoans(unions)  
    }

    const getTotalOld = (quotes) => {
        let totalOld=0
        quotes.map((prop, key) => {
            if(!isUnion(prop.id)){
                totalOld+=parseInt(prop.quote_day)
                
            }
            
        })
        setTotalOld(totalOld)
    }

    const getTotalOldReturn = (quotes) => {
        
        let totalOld=0
        quotes.map((prop, key) => {
            if(!isUnion(prop.customer_id)){
                totalOld+=parseInt(prop.quote_day)
                
            }
            
        })
        return totalOld
    }

    const clearForm = () =>{
        setReportData(
            {
                newLoans:[],
                oldLoans:[],
                noUpdate:[],
                payments:[],
                totalActive:0,
                totalSale:0,
                total:0
            }
        )
        setPapeleria(0)
        setPapeleriaReal(0)
        setPapeleriaEntra(0)
        setBarba(0)
        setBarbaEntra(0)
        setTrabajadorEntra(0)
        setEntrega(0)
        setUnionLoans({})
        setTotalNew(0)
        setTotalOld(0)
        setPleno(0)
        setBase(0)
        setDebeEntregar(0)
        setFaltantePapeleria(0)
        setPago(0)
        setAbonos(0)
        setInject(0)
        setBeardJob(0)
        setSupRevisa('')
        setSupVerifica('')
        setAdditional(0)
        setBeardPp(0)
        setBeardBase(0)
        setBaseSobrante(0)
        setDetail("")
    }

    const hasPermissions = () =>{
        return  rol == 1 || rol == 3 || rol == 4 || rol == 6
    }

    const renderReportLoans = (quotes) => {
        let id = 0
        let data = []
        let totalOld=0
        quotes.map((prop, key) => {
            id++;
            if(!isUnion(prop.id)){
                totalOld+=parseInt(prop.quote_day)
                data.push(
                    <tr key={`qq${prop.id}`}>
                        <td>{id}</td>
                       <td>{CURRENCY.format(prop.quote_day)}</td>
                       <td>{prop.customer.name}</td>
                       <td>{prop.customer.phone}</td>
                       <td>{CURRENCY.format(prop.value)}</td>
                       <td>{CURRENCY.format(prop.balance)}</td>
                       <td>{prop.payment_mode.name}</td>
                   </tr>
                   
               )
            }
            
        })
        return data
    }

    const renderReportPayments = (payments) => {
        let id = 0
        let data = []
        let totalPay=0
        payments.map((prop, key) => {
            id++;
            totalPay+=parseInt(prop.value)
            data.push(
                <tr key={`pay${id}`}>
                    <td>{id}</td>
                    <td>{prop.loan.customer.name}</td>
                    <td>{+parseInt(prop.debt_balance/1000)}</td>
                    <td>
                        <Input
                        className="form-control-alternative"
                        value={prop.value/1000}
                        type="number"
                        onChange ={e => updatePay(e.target.value, prop.id)}
                        onFocus={(event) => event.target.select()}>
                        </Input>
                    </td>
                </tr>
                
            )
        })
        //setPago(totalPay)
        return data
    }
    
    const getClavoDiscount =() =>{
        if(reportData.newLoans.length > 0){
            return 0
        }else{
            return parseInt(barbaEntra) + parseInt(papeleriaEntra) + parseInt(trabajadorEntra)
        }
    }


    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte De Préstamos del día
                                        </h6>
                                        <Row>
                                            <Col lg="8">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                            className="form-control-label"
                                                            htmlFor="startDate">
                                                                Fecha Inicio
                                                            </label>
                                                            <Input
                                                            className="form-control-alternative"
                                                            id="startDate"
                                                            name="startDate"
                                                            value={startDate}
                                                            onChange={e=>{clearForm()
                                                                setStartDate(e.target.value)}
                                                            }
                                                            type="date" >
                                                            </Input>
                                                                    
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label
                                                            className="form-control-label"
                                                            htmlFor="userId"
                                                            >
                                                            Usuario
                                                            </label>
                                                            <Input
                                                            className="form-control-alternative"
                                                            id="userId"
                                                            name="userId"
                                                            placeholder="Seleccionar una opción"
                                                            type="select"
                                                            onChange={e=>{
                                                                clearForm()
                                                                setUserReport(e.target.value)
                                                                }}>
                                                                <option></option>
                                                                {renderOptions(users)}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>                            
                                                </Row>
                                            </Col>
                                            <Col lg="4">
                                                <Row>
                                                    <Col lg="3">
                                                        <Button className="my-4" color="primary" type="submit" onClick={()=>{getReportLoanRange()}}>
                                                            Consultar
                                                        </Button>
                                                    </Col>   
                                                    <Col lg="3">
                                                        <Button className="my-4" color="success" type="submit" onClick={()=>{saveSale()}}>
                                                            Guardar
                                                        </Button>
                                                    </Col>    
                                                    <Col lg="3">
                                                        <Button className="my-4" color="secundary" type="info" onClick={handlePrint}>Imprimir</Button>
                                                    </Col>   
                                                    <Col lg="3">
                                                        <Button className="my-4" color="warning" type="info" onClick={()=> removeSale()}>Eliminar</Button>
                                                    </Col>  
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>

                                <Row>
                                    <Col md="5" scope="div">
                                        <Row>                      
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="pleno">
                                                        Pleno
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="pleno"
                                                    name="pleno"
                                                    readOnly={true}
                                                    disabled={true}
                                                    value={`${(parseInt(pleno)/1000)-getClavoDiscount()-abonos} + ${tLoans/1000}`}
                                                    onChange={e=>{}}
                                                    type="text" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>

                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="base">
                                                        Base
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="base"
                                                    name="base"
                                                    value={base}
                                                    onChange={e=>{setBase(e.target.value)}}
                                                    onFocus={(event) => event.target.select()}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                            
                                            
                                            { hasPermissions() &&
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="debeEntregar">
                                                        Debe Entregar
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="debeEntregar"
                                                    name="debeEntregar"
                                                    readOnly={true}
                                                    disabled={true}
                                                    value={`${(debeEntregar/1000)+parseInt(base)+parseInt(inject)-parseInt(payment)-parseInt(beardJob)-getClavoDiscount()}`}
                                                    onChange={e=>{}}
                                                    type="text" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>  
                                            }
                                        </Row>
                                    </Col>
                                    <Col md="7" scope="div">
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="entrega">
                                                        Entrega
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="entrega"
                                                    name="entrega"
                                                    value={entrega}
                                                    onChange={e=>{setEntrega(e.target.value)}}
                                                    onFocus={(event) => event.target.select()}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>

                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="faltante">
                                                        {  (((debeEntregar/1000)+parseInt(base)+parseInt(inject)-parseInt(payment)-parseInt(beardJob)-getClavoDiscount()) - entrega - additional) >= 0? "Faltante" : "Sobrante" }
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="faltante"
                                                    name="faltante"
                                                    readOnly={true}
                                                    disabled={true}
                                                    value={`${(((debeEntregar/1000)+parseInt(inject)+parseInt(base)-parseInt(payment)-parseInt(beardJob)-getClavoDiscount()) - entrega - additional)}`}
                                                    onChange={e=>{}}
                                                    type="text" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>

                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="faltantePapeleria">
                                                        {
                                                            parseInt(faltantePapeleria)+parseInt(papeleriaReal) <= 0 ? 'F. Papelería':'Sob. Papelería'
                                                        }
                                                        
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="faltantePapeleria"
                                                    name="faltantePapeleria"
                                                    readOnly={true}
                                                    disabled={true}
                                                    value={`${parseInt(faltantePapeleria)+parseInt(papeleriaReal)}`}
                                                    onChange={e=>{}}
                                                    type="text" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>

                                            <Col lg="3" >
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="pago" 
                                                    style={{cursor:"pointer"}}
                                                    onClick={()=> {openModalPayment()}}>
                                                        Pagó
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="pago"
                                                    name="pago"
                                                    value={payment}
                                                    readOnly={true}
                                                    disabled={true}
                                                    onFocus={(event) => event.target.select()}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col lg="2">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="inject">
                                                Inyección
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="inject"
                                                name="inject"
                                                value={inject}
                                                onChange={e=>{setInject(e.target.value)}}
                                                onFocus={(event) => event.target.select()}
                                                type="number" >
                                            </Input>
                                                    
                                        </FormGroup>
                                    </Col>
                                   
                                    <Col lg="2">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="supVerifica"
                                            >
                                            Sup Verifica
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="supVerifica"
                                            name="supVerifica"
                                            placeholder="Nombre del supervisor"
                                            type="text"
                                            onChange={e=>{setSupVerifica(e.target.value)}}
                                            value={supVerifica}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>     
                                    <Col lg="2">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="supRevisa"
                                            >
                                            Sup Revisa
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="supRevisa"
                                            name="supRevisa"
                                            placeholder="Nombre del supervisor"
                                            type="text"
                                            value={supRevisa}
                                            onChange={e=>{setSupRevisa(e.target.value)}}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>              
                                    <Col lg="2">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="abonos">
                                                Abonos
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="abonos"
                                            name="abonos"
                                            value={abonos}
                                            onChange={e=>{setAbonos(e.target.value)}}
                                            onFocus={(event) => event.target.select()}
                                            type="number" >
                                            </Input>
                                                    
                                        </FormGroup>
                                    </Col>
                                    <Col lg="1">
                                        <Button className="my-4" color="info" type="info" onClick={()=> {openModal()}}>Abonos</Button>
                                    </Col> 
                                    <Col lg="2">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="additional">
                                                Entrega Adicional
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="additional"
                                            name="additional"
                                            value={additional}
                                            onChange={e=>{setAdditional(e.target.value)}}
                                            onFocus={(event) => event.target.select()}
                                            type="number" >
                                            </Input>
                                                    
                                        </FormGroup>
                                    </Col>
                                    <Col lg="1">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="entryTotal">
                                                Total Entrega
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="entryTotal"
                                            name="entryTotal"
                                            value = {parseInt(additional)+parseInt(entrega)}
                                            readOnly={true}
                                            disabled={true}
                                            type="number" >
                                            </Input>
                                                    
                                        </FormGroup>
                                    </Col>
                                      
                                </Row>
                                <br/>
                                <PrintComponent rol={rol} startDate={startDate} saveSale={saveSale} 
                                entrega={entrega} setEntrega={setEntrega} updateEntrega={updateEntrega}
                                debeEntregar={debeEntregar} setDebeEntregar={setDebeEntregar} 
                                updateDebe={updateDebe} updateDio={updateDio}
                                pleno={pleno} setPleno={setPleno}
                                abonos= {abonos} setAbonos={setAbonos}
                                papeleriaReal={papeleriaReal} setPapeleriaReal={setPapeleriaReal} 
                                barba={barba} setBarba={setBarba} barbaEntra={barbaEntra} setBarbaEntra={setBarbaEntra} 
                                trabajadorEntra = {trabajadorEntra} setTrabajadorEntra={setTrabajadorEntra}
                                papeleria={papeleria} setPapeleria={setPapeleria} papeleriaEntra={papeleriaEntra} setPapeleriaEntra={setPapeleriaEntra} 
                                totalNew={totalNew} totalOld={totalOld} setTotalNew={setTotalNew} setTotalOld={setTotalOld} tLoans={tLoans}
                                unionLoans={unionLoans} deleteUnion={deleteUnion} updateUnion={updateUnion}  
                                getTotalOld={getTotalOld} pago={pago} setPago={setPago} hasPermissions={hasPermissions} payment={payment}
                                base = {base} setBase={setBase} faltantePapeleria={faltantePapeleria} setFaltantePapeleria={setFaltantePapeleria}
                                reportData={reportData} setReportData={setReportData} username={userReport? getUserReport(userReport).name:""} ref={componentRef} />

                                <br/>
                                <Row>
                                    { hasPermissions() && 
                                    <>
                                        <Col lg="2">
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                        className="form-control-label"
                                                        htmlFor="papeleria">
                                                            Papelería
                                                        </label>
                                                        <Input
                                                        className="form-control-alternative"
                                                        id="papeleria"
                                                        name="papeleria"
                                                        readOnly={true}
                                                        disabled={true}
                                                        value={parseInt(papeleria)}
                                                        onChange={e=>{}}
                                                        type="number" >
                                                        </Input>
                                                                
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                        className="form-control-label"
                                                        htmlFor="papeleria2">
                                                        - Papelería Descontada
                                                        </label>
                                                        <Input
                                                        className="form-control-alternative"
                                                        name="papeleria2"
                                                        value={parseInt(papeleriaReal)}
                                                        onChange={e=>{setPapeleriaReal(e.target.value)}}
                                                        onFocus={(event) => event.target.select()}
                                                        type="number" >
                                                        </Input>        
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                        className="form-control-label"
                                                        htmlFor="papeleriatotal">
                                                            Papelería Real
                                                        </label>
                                                        <Input
                                                        className="form-control-alternative"
                                                        name="papeleriatotal"
                                                        readOnly={true}
                                                        disabled={true}
                                                        value={parseInt(papeleria-papeleriaReal)}
                                                        onChange={e=>{}}
                                                        type="number" >
                                                        </Input>    
                                                    </FormGroup>
                                                </Col>
                                                
                                            </Row>
                                        </Col>
                                        <Col lg="2">
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                        className="form-control-label"
                                                        htmlFor="ingresoPapeleria">
                                                            Ingreso Papelería
                                                        </label>
                                                        <Input
                                                        className="form-control-alternative"
                                                        id="ingresoPapeleria"
                                                        name="ingresoPapeleria"
                                                        value={papeleriaEntra}
                                                        onChange={e=>{setPapeleriaEntra(e.target.value)}}
                                                        onFocus={(event) => event.target.select()}
                                                        type="number" >
                                                        </Input>
                                                                
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                        className="form-control-label"
                                                        htmlFor="ingresoBarba">
                                                            Ingreso Barba
                                                        </label>
                                                        <Input
                                                        className="form-control-alternative"
                                                        id="ingresoBarba"
                                                        name="ingresoBarba"
                                                        value={barbaEntra}
                                                        onChange={e=>{setBarbaEntra(e.target.value)}}
                                                        onFocus={(event) => event.target.select()}
                                                        type="number" >
                                                        </Input>
                                                                
                                                    </FormGroup>
                                                </Col>  
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label
                                                        className="form-control-label"
                                                        htmlFor="clavo">
                                                            Tarjeta Clavo
                                                        </label>
                                                        <Input
                                                        className="form-control-alternative"
                                                        id="clavo"
                                                        name="clavo"
                                                        readOnly={true}
                                                        disabled={true}
                                                        value={parseInt(papeleriaEntra) + parseInt(barbaEntra)}
                                                        onChange={e=>{}}
                                                        type="number" >
                                                        </Input>
                                                                
                                                    </FormGroup>
                                                </Col>
                                                    
                                            </Row> 

                                            
                                        </Col>                                            
                                        <Col lg="2">
                                          
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="beardPp">
                                                        Base Papelería
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="beardPp"
                                                    name="beardPp"
                                                    value={beardPp}
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={e=>{setBeardPp(e.target.value)}}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                                
                                        </Row>   

                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="beardBase">
                                                        Base Barba
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="beardBase"
                                                    name="beardBase"
                                                    value={beardBase}
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={e=>{setBeardBase(e.target.value)}}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                                
                                        </Row>  

                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="baseSobrante">
                                                        Base Sobrante
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="baseSobrante"
                                                    name="baseSobrante"
                                                    value={baseSobrante}
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={e=>{setBaseSobrante(e.target.value)}}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                                
                                        </Row>  
                                    
                                    </Col>
                                    <Col lg="4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="beardJob">
                                                        Barba Cobrador
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="beardJob"
                                                    name="clbeardJobavo"
                                                    value={beardJob}
                                                    onFocus={(event) => event.target.select()}
                                                    onChange={e=>{setBeardJob(e.target.value)}}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                                
                                        </Row> 
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="ingresoBarba">
                                                        Ingreso Cobrador
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="ingresoBarba"
                                                    name="ingresoBarba"
                                                    value={trabajadorEntra}
                                                    onChange={e=>{setTrabajadorEntra(e.target.value)}}
                                                    onFocus={(event) => event.target.select()}
                                                    type="number" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>  
                                        </Row>
                                        
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="detail">
                                                        Detalle
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="detail"
                                                    name="detail"
                                                    value={detail}
                                                    onChange={e=>{setDetail(e.target.value)}}
                                                    type="text" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>  
                                        </Row>
                                        
                                    </Col>
                                    </>
                                    } 
                                    
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">Deuda</th>
                                                    <th scope="col">Pago</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reportData.payments.length > 0 && renderReportPayments(reportData.payments)}
                                            </tbody>
                                        </Table> 
                                    </Col>
                                </Row>

                                <br/>

                                <Row>
                                    <h6 className="heading-small text-muted mb-4">
                                        Préstamos no renovados - {userReport? getUserReport(userReport).name:""} - Fecha: {startDate} 
                                    </h6>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">U. saldo</th>
                                                <th scope="col">Cliente</th>
                                                <th scope="col">Teléfono</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col">Saldo</th>
                                                <th scope="col">Tipo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportData.noUpdate.length > 0 && renderReportLoans(reportData.noUpdate)}
                                        </tbody>
                                    </Table> 
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpenPayment}
                    size="lg"
                    toggle={() => closeModalPayment()}
                    >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Pagos del día
                        </h5>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>closeModalPayment()}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Payments startDate={startDate} userReport={userReport} />
                    </div>
                    </Modal>
                    <Modal
                    className="modal-dialog-centered"
                    isOpen={modalOpen}
                    size="lg"
                    toggle={() => closeModal()}
                    >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Posibles Abonos
                        </h5>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>closeModal()}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <BadQuotes saleLoans={reportData.oldLoans} startDate={startDate} userReport={userReport} />
                    </div>
                    </Modal>
            </Container>
            
        </>
    );
};

class PrintComponent extends React.Component {

    isUnion = (customer_id) => {
        return this.props.unionLoans[customer_id]
    }

    renderDropDown = (customer_id) => {
        const OldLoans = this.props.reportData.noUpdate
        let data = []
        let id = 0
        OldLoans.map((prop, key) => {  
            if(!this.isUnion(prop.id)){
                id++
                data.push(<DropdownItem key={`dd${id}`}
                    onClick={e => this.props.updateUnion(prop, customer_id)}
                >
                    {prop.customer.name}
                </DropdownItem>)
            }    
            
        })
        return data
    }

    renderNewLoans = (loans) => {
        let totalbarba = 0
        let id = 0
        let sumLoans = 0
        let newLoans = []
        let totalDio = 0
        let totalLoans = 0
        let totalIngresa = 0
        let totalEntrega = 0 
        let totalDebe = 0
        let totalFaltantePapeleria = 0 
        
        let data = loans.map((prop, key) => {
            id++;
            sumLoans+= parseInt(prop.value)
            if(!prop.entrega){
                prop.entrega = 0
            }

            if(!prop.debe){
                prop.debe = 0
            }

            if(!prop.dio){
                prop.dio = 0
            }

            let sale = 0
            let lastLoan=0
            let saleString = ""
            let name = prop.customer.name
            
            if(prop.union && prop.union.length > 0){
                sale = prop.OldLoan ? parseInt(prop.OldLoan.quote_day) : 0
                name =  `${prop.customer.name}`
                saleString = `${CURRENCY.format(parseInt(prop.OldLoan.quote_day)/1000)}`
                prop.union.forEach(lc => {
                    name += ` + ${lc.customer.name}`
                    saleString += `+ ${CURRENCY.format(parseInt(lc.quote_day)/1000)}`
                    sale += parseInt(lc.quote_day)
                });
                
                
            }else{
                sale = prop.OldLoan ? parseInt(prop.OldLoan.quote_day) : 0
                saleString = prop.OldLoan ? CURRENCY.format(parseInt(prop.OldLoan.quote_day)/1000) : 0
                lastLoan = prop.OldLoan ? parseInt(prop.OldLoan.value) : 0
            }

            prop.ingresa = prop.total - (prop.total - prop.balance_day)
            prop.papeleria = (prop.value*0.04)
            let barba = ( parseInt(prop.value)-(parseInt(sale)+prop.ingresa+parseInt(prop.entrega)+parseInt(prop.papeleria)+parseInt(prop.debe)-parseInt(prop.dio)) )/1000
            if(barba>=0){
                totalbarba += barba
            }else{
                totalFaltantePapeleria += barba
            }
            
            newLoans.push(prop)
            totalDio += parseInt(prop.dio )
            totalIngresa += parseInt(prop.ingresa)
            totalEntrega += parseInt(prop.entrega)
            totalDebe += parseInt(prop.debe)
            totalLoans += parseInt(prop.value)
            return  (
                 <tr key={`q${prop.id}`}>
                    <td>{id}</td>
                    <td>{saleString}</td>
                    <td>{CURRENCY.format((prop.ingresa)/1000)}</td>
                    <td>
                        {name}
                        {prop.OldLoan &&
                        (<UncontrolledDropdown>
                            <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                            >
                                <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                {this.renderDropDown(prop.customer_id)}
                            </DropdownMenu>
                        </UncontrolledDropdown>)
                        
                        }
                        {
                            prop.union  && prop.union.length > 0 &&  
                        
                            <span className="btn-inner--icon" style={
                                {
                                    cursor:"pointer"
                                }
                                
                            }
                            onClick={e=>{
                              this.props.deleteUnion(prop.customer_id, prop.union)      
                            }}>
                                <i className="ni ni-fat-remove" />
                            </span>
                        }
                    </td>
                    <td>
                        <Input
                        className="form-control-alternative"
                        value={prop.entrega/1000}
                        type="number" 
                        onChange ={e => this.props.updateEntrega(e.target.value, prop.customer_id)}
                        onFocus={(event) => event.target.select()}>
                        </Input>
                        </td>
                    <td>{prop.customer.phone}</td>
                    <td>
                        <Input
                        className="form-control-alternative"
                        value={prop.debe/1000}
                        type="number" 
                        onChange ={e => this.props.updateDebe(e.target.value, prop.customer_id)}
                        onFocus={(event) => event.target.select()}>
                        </Input>
                    </td>
                    <td>{CURRENCY.format(lastLoan/1000)}</td>
                    <td>{CURRENCY.format(prop.value/1000)}</td>
                    <td>
                        <Input
                        className="form-control-alternative"
                        value={prop.dio/1000}
                        type="number"
                        onChange ={e => this.props.updateDio(e.target.value, prop.customer_id)}
                        onFocus={(event) => event.target.select()}>
                        </Input>
                    </td>
                    <td>{CURRENCY.format(prop.balance/1000)}</td>
                    <td>
                        <b>{CURRENCY.format(barba)}</b>
                    </td>
                </tr>
                
            )
        })
        
        data.push(
            <tr key="th1" className="thead-light">
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">{totalIngresa/1000}</th>
                <th scope="col"></th>
                <th scope="col">{totalEntrega/1000}</th>
                <th scope="col"></th>
                <th scope="col">{totalDebe/1000}</th>
                <th scope="col"></th>
                <th scope="col">{totalLoans/1000}</th>
                <th scope="col">{totalDio/1000}</th>
                <th scope="col"></th>
                <th scope="col">{totalbarba}</th>
            </tr>
        )
        this.props.setBarba(totalbarba)
        this.props.setFaltantePapeleria(totalFaltantePapeleria)
        this.props.setTotalNew(totalDio - totalIngresa)

        const plenoIn = parseInt(this.props.reportData.totalActive) + totalDio - totalIngresa - ( ( parseInt(this.props.papeleriaEntra) + parseInt(this.props.barbaEntra) + parseInt(this.props.trabajadorEntra) ) *1000 ) 
        this.props.setPleno( plenoIn )
        this.props.setPapeleria((sumLoans/1000) * 0.04)

        this.props.setDebeEntregar(plenoIn + parseInt(this.props.tLoans) - (this.props.abonos*1000) - totalEntrega - parseInt(this.props.reportData.totalExpenses) )
    
        return data
    }

    render() {
        return (
            <>
                <div>
                    <Row>
                        <h6 className="heading-small text-muted mb-4">
                            Préstamos del día - {this.props.username} - Fecha: {this.props.startDate} 
                        </h6>
                    </Row>

                    <Row className="page-break">
                        <h4 className="heading-small text-muted mb-4">
                            Pleno: {`${parseInt(this.props.pleno)/1000} + ${ this.props.totalOld/1000 }`} - 
                            Base: {this.props.base} -  
                            {this.props.hasPermissions() && `Debe entregar: ${(this.props.debeEntregar/1000)+parseInt(this.props.base)-parseInt(this.props.payment)}`} - 
                            Entrega: {this.props.entrega} - 
                            {(((this.props.debeEntregar/1000)+parseInt(this.props.base)-parseInt(this.props.payment)) - this.props.entrega) >= 0? "Faltante" : "Sobrante"} {(((this.props.debeEntregar/1000)+parseInt(this.props.base)-parseInt(this.props.payment)) - this.props.entrega)} - 
                            F. Papeleria: {`${parseInt(this.props.faltantePapeleria)+parseInt(this.props.papeleriaReal)}`} - 
                            Pagó: {this.props.payment} 
                        </h4>
                    </Row>
                    
                    <Row>
                        <Table className="align-items-center table-flush table-report-sale" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">U. Saldo</th>
                                    <th scope="col">Ingresa</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Entrega</th>
                                    <th scope="col">Teléfono</th>
                                    <th scope="col">Debe</th>
                                    <th scope="col">C.Anterior</th>
                                    <th scope="col">C.Nuevo</th>
                                    <th scope="col">Dio</th>
                                    <th scope="col">Saldo</th>
                                    <th scope="col"><b>Barba</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.reportData.newLoans.length > 0 && this.renderNewLoans(this.props.reportData.newLoans)}
                            </tbody>
                        </Table> 
                    </Row>

                </div>
            </>
            
        );
    }
}


export default FinalSale;