/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
//import Profile from "views/examples/Profile.js";
//import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/Login";
//import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import Users from "views/Users"
import Expenses from "views/Expenses";
import Company from "views/Company";
import Owner from "views/Owner";
import Customers from "views/Customers";
import Injection from "views/Injection";
import Bases from "views/Bases";
import Dashboard from "views/Dashboard";
import Sale from "views/Dashboard/Sale";
import Day from "views/Dashboard/Day";
import Charts from "views/Dashboard/Charts";
import General from "views/Dashboard/General";
import DelayLoans from "views/Dashboard/DelayLoans";
import LoanDays from "views/Dashboard/LoanDays";
import Balance from "views/Dashboard/Balance";
import Log from "views/Dashboard/Log";
import GeneralDetail from "views/Dashboard/GeneralDetail";
import Backup from "views/Backup";
import FinalSale from "views/FinalSale" ;

var routes = [
  {
    path: "/index",
    name: "Tablero",
    icon: "ni ni-sound-wave text-secondary",
    component: Charts,
    layout: "/admin",
    users:[6,1,3,4],
  },
  {
    path: "/clientes",
    name: "Clientes",
    icon: "ni ni-single-02 text-secondary",
    component: Customers,
    users:[6,1,3,4],
    layout: "/admin"
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "ni ni-circle-08 text-secondary",
    users:[6,1,4],
    component: Users,
    layout: "/admin"
  },
  {
    path: "/empresas",
    name: "Empresas",
    users:[6],
    icon: "ni ni-building text-secondary",
    component: Owner,
    layout: "/admin"
  },
  {
    path: "/oficinas",
    name: "Oficinas",
    users:[6,1],
    icon: "ni ni-shop text-secondary",
    component: Company,
    layout: "/admin"
  },
  {
    path: "/inyeccion",
    name: "Inyección",
    icon: "ni ni-spaceship text-secondary",
    users:[6,1,3,4],
    component: Injection,
    layout: "/admin"
  },
  {
    path: "/base",
    name: "Base",
    users:[6,1,3,4],
    icon: "ni ni-money-coins text-secondary",
    component: Bases,
    layout: "/admin"
  },
  {
    path: "/gastos",
    name: "Gastos",
    users:[6,1,3,4],
    icon: "ni ni-archive-2 text-secondary",
    component: Expenses,
    layout: "/admin"
  } ,
  {
    path: "/liquidacion",
    name: "Liquidación",
    icon: "ni ni-trophy text-secondary",
    users:[6,1,3,4],
    component: FinalSale,
    layout: "/admin"
  } ,
  /*{
    path: "/backup",
    name: "Backup",
    users:[6],
    icon: "ni ni-cloud-download-95 text-secondary",
    component: Backup,
    layout: "/admin"
  },*/
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/dashboard",
    name: "Tablero",
    icon: "",
    component: Charts,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/sale",
    name: "Cuadre del día",
    icon: "",
    component: Sale,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/day",
    name: "Reporte del día",
    icon: "",
    component: Day,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/general",
    name: "Reporte General",
    icon: "",
    component: General,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/detail/general",
    name: "Reporte General 2",
    icon: "",
    component: GeneralDetail,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/loansdelay",
    name: "Clientes en mora",
    icon: "",
    component: DelayLoans,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/loansday",
    name: "Préstamos del día",
    icon: "",
    component: LoanDays,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/daysbalance",
    name: "Saldo Actual",
    icon: "",
    component: Balance,
    layout: "/report",
    users:[6,1,3,4],
  },
  {
    path: "/logs",
    name: "Bitácora",
    icon: "",
    component: Log,
    layout: "/report",
    users:[6,1,4],
  }
];
export default routes;
