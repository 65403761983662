import React,{useState,useEffect} from 'react';
import {
    Button,
    FormGroup,
    FormText,
    Form,
    Input,
    Row,
    Col,
  } from "reactstrap";
import { useToast,useApi } from '../../../hooks';
import { useForm } from "react-hook-form";

const Loan = ({customer}) => {

    if(!customer){
      customer = {
        id:0,
        name:""
      }
    }
   
    const [paymentModes, setPaymentModes] = useState([])

    const toast = useToast();
    const { getPaymentsMode, storeLoan } = useApi();
    
    const saveLoan = async data => {
        //console.log(data);
        const { response, error } = await storeLoan({...data, customerId: customer.id});
        if (response) {
          //console.log(response);
          const { content, message } = response.data;
          toast.success(message);
          reset();
        } else if (error) {
          if(error.response.status == 400){
            error.message && toast.error(error.response.data.message);
          }else{
            error.message && toast.error(error.message);
          } 
        }
    }

    const getPaymentsModeList = async () =>{
   
        const { response, error } = await getPaymentsMode();
        if (response) {
          const { content, message } = response.data;
          setPaymentModes(content);
        } else if (error) {
            error.message && toast.error(error.message);
        }
    
      }

    const { register, handleSubmit, errors, setValue, reset  } = useForm({
        validateCriteriaMode: "all",
        mode: "onChange"
    });

    const renderOptions = (list) => {
        return list.map((prop,key) => {
        return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    const renderOptionsQuotes = (number) => {
        let element = [];
        for (let index = 1; index <= number; index++) {
            element.push( <option key ={`q_${index}`} value={index}>{index}</option> )
        }
        return element
    }

    useEffect(()=>{
        getPaymentsModeList();
      }, [])
    
    return (
        <>
          <Form onSubmit={handleSubmit(saveLoan)}>
                  <h6 className="heading-small text-muted mb-4">
                    Nuevo prestamo a {customer.name}
                  </h6>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="date"
                        >
                          Fecha de entrega del préstamo
                        </label>
                        <Input 
                            name = "date"
                            className="form-control-alternative"
                            id="date"
                            type="date"
                            innerRef={register({ required: true})} 
                        />
                        {!!errors?.date?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="paymentModeId"
                        >
                          Modo de Pago
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="paymentModeId"
                          name="paymentModeId"
                          placeholder="Seleccionar una opción"
                          type="select"
                          innerRef={register({ required: true })} >
                            <option></option>
                            {renderOptions(paymentModes)}
                        </Input>
                        {!!errors?.name?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="value"
                        >
                          Valor
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="value"
                          name="value"
                          type="number"
                          innerRef={register({ required: true})} 
                        />
                        {!!errors?.value?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                    
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="percentage"
                        >
                          Porcentaje (%)
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="percentage"
                          name="percentage"
                          type="number"
                          innerRef={register({ required: true})} 
                        />
                        {!!errors?.percentage?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                  <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="quotes"
                        >
                          Número de cuotas
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="quotes"
                          name="quotes"
                          placeholder="Seleccionar una opción"
                          type="select"
                          innerRef={register({ required: true })} >
                            <option></option>
                            {renderOptionsQuotes(120)}
                        </Input>
                        {!!errors?.quotes?.types?.required && <FormText>Campo requerido.</FormText>}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="submit" >
                            Guardar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>  
        </>
    );
};

export default Loan;