import {  createActions } from 'redux-actions';

const actions = createActions({
    LOGIN_SUCCESS: (user) => ({ user }),
    LOGOUT: () => {}
});

const { loginSuccess, logout, setCompany } = actions;

export default {
    loginSuccess,
    logout
};
