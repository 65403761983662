/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";
import map from 'lodash.map';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Badge,
  Table
} from "reactstrap";
import { useForm } from "react-hook-form";
// core components
import Header from "components/Headers/Header.js";
import { useApi, useToast } from "../../hooks";
import PaginationComponent from "components/Pagination";

const Users = () => {

  const { register, handleSubmit, errors, setValue, reset  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  const [users, setUsers] = useState([])
  const [companies, setCompanies] = useState([])
  const [roles, setRoles] = useState([])
  const [update, setUpdate] = useState(false)
  const [pagination, setPagination] = useState({
    perPage: 50,
    page:1,
    pages:0
  });

  const toast = useToast();
  const { storeUser, getUsersPagination, changeUserStatus, deleteUser, getRoles, getCompanies } = useApi();

  const getRolList = async () =>{
   
    const { response, error } = await getRoles();
    if (response) {
      const { content, message } = response.data;
      setRoles(content);
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getCompanyList = async () =>{
   
    const { response, error } = await getCompanies();
    if (response) {
      const { content, message } = response.data;
      setCompanies(content);
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getUserListPage = async (paginationObject) =>{
   
    const { response, error } = await getUsersPagination(paginationObject);
    if (response) {
      const { content, message } = response.data;
      setUsers(content.data);
      setPagination({
        perPage: content.per_page,
        page: content.current_page,
        pages: content.last_page
      })
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }
  
  const saveUser = async data => {
    if(!update){
      delete data.id;
    }
    const { response, error } = await storeUser(data);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      reset();
      getUserListPage(pagination)
      
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const changeStatusUser = async data => {
    
    const object = {
      userId: data.id,
      status: data.status
    }
    const { response, error } = await changeUserStatus(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      
      getUserListPage(pagination)
      
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const deleteSelectedUser = async data => {
    
    const object = {
      userId: data.id
    }
    const { response, error } = await deleteUser(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      getUserListPage(pagination)
      
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const fillUser = (user) =>{
    
    setValue([
      { id : user.id },
      { rolId : user.rol_id },
      { companyId : user.company_id },
      { name : user.name },
      { email : user.email },
      { username : user.username },
      { imei : user.imei },
      { password : 'fakeAgroApp' }
    ])
    setUpdate(true)

  }

  const renderOptions = (list) => {
    return list.map((prop,key) => {
      return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
    });
  }

  const renderCompanyOptions = (list) => {
    return list.map((prop,key) => {
      return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name} - {prop.owner.name}</option>
    });
  }

  const renderTable = (users) => {
        let data = users.map((prop, key) => {
            return  (
                  <tr key={prop.id}>
                    <th scope="row">{prop.username}</th>
                    <td>{prop.name}</td>
                    <td>{prop.rol.name}</td>
                    <td>{prop.owner.name}</td>
                    <td>{prop.company.name}</td>
                    <td>
                      {
                        prop.status == 1? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Activo
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          Inactivo
                        </Badge>
                      }
                     
                    </td>
                  <td className="text-left">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        className="btn-icon-only text-light"
                        href="#"
                        role="button"
                        size="sm"
                        color=""
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                          href="#"
                          onClick={() => fillUser(prop)}
                        >
                          Actualizar
                        </DropdownItem>
                        <DropdownItem
                          href="#"
                          onClick={() => changeStatusUser(prop)}
                        >
                          Cambiar Estado
                        </DropdownItem>
                        <DropdownItem
                          href="#"
                          onClick={()=> deleteSelectedUser(prop)}
                        >
                          Eliminar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                
                </tr>
                
                )
        })
        return data;
  }

  const onPagination = (pagination)=>{
    getUserListPage(pagination);
  }
   
  useEffect(()=>{
    getUserListPage(pagination);
    getCompanyList();
    getRolList();
  }, [])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Crear Usuario</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(saveUser)}>
                  <h6 className="heading-small text-muted mb-4">
                    Información del Usuario
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                          className="form-control-label"
                          htmlFor="companyId"
                          >
                              Oficina
                          </label>
                          <Input
                          className="form-control-alternative"
                          id="companyId"
                          name="companyId"
                          placeholder="Seleccionar una opción"
                          type="select"
                          innerRef={register({ required: true })} >
                              <option></option>
                              {renderCompanyOptions(companies)}
                          </Input>
                          {!!errors?.companyId?.types?.required && <FormText>Campo requerido.</FormText>}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                      <FormGroup>
                          <label
                          className="form-control-label"
                          htmlFor="rolId"
                          >
                              Tipo de usuario
                          </label>
                          <Input
                          className="form-control-alternative"
                          id="rolId"
                          name="rolId"
                          placeholder="Seleccionar una opción"
                          type="select"
                          innerRef={register({ required: true })} >
                              <option></option>
                              {renderOptions(roles)}
                          </Input>
                          {!!errors?.rolId?.types?.required && <FormText>Campo requerido.</FormText>}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="name"
                          >
                            Nombre
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="name"
                            name="name"
                            placeholder="Nombre"
                            type="text"
                            innerRef={register({ required: true })} 
                          />
                          {!!errors?.name?.types?.required && <FormText>Campo requerido.</FormText>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="email"
                          >
                              Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="email"
                            innerRef={register()} 
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="username"
                          >
                            Usuario
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="username"
                            name="username"
                            placeholder="Nombre de Usuario"
                            type="text"
                            innerRef={register({ required: true})} 
                          />
                          {!!errors?.username?.types?.required && <FormText>Campo requerido.</FormText>}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="password"
                          >
                              Contraseña
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="password"
                            name="password"
                            placeholder="Contraseña"
                            type="password"
                            innerRef={register({ required: true })} 
                          />
                          {!!errors?.password?.types?.required && <FormText>Campo requerido.</FormText>}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="imei"
                          >
                            Imei
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="imei"
                            name="imei"
                            placeholder="Imei"
                            type="text"
                            innerRef={register()} 
                          />
                        </FormGroup>
                      </Col>
                      <Col xl="2">
                          <div className="text-center">
                            {
                              !update ? 
                              <Button className="my-4" color="primary" type="submit" >
                                  Guardar
                              </Button>
                              :
                              <>
                                <Button className="my-4" color="danger" type="button" onClick={()=>{reset(); setUpdate(false)}} >
                                   Cancelar
                                </Button>
                                <Button className="my-4" color="info" type="submit" >
                                    Actualizar datos
                                </Button>
                              </>
                            }
                          </div>
                        </Col>
                        <Input
                            className="form-control-alternative"
                            hidden
                            id="id"
                            name="id"
                            type="number"
                            innerRef={register} 
                        />
                    </Row>
                  </div>
                
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Lista de Usuarios</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Usuario</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Rol</th>
                      <th scope="col">Empresa</th>
                      <th scope="col">Oficina</th>
                      <th scope="col">Estado</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 && renderTable(users)}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <PaginationComponent pagination={pagination} handlePage={onPagination} />
                </CardFooter>
              </Card>
            </Col>
        </Row>
      </Container>
    </>
  );
};

export default Users;


