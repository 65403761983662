/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState,useEffect} from "react";
import moment from 'moment'
import { CURRENCY } from '../../variables/app';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormText,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Badge,
  Table,
  Button
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { useForm } from "react-hook-form";
import { useApi, useToast } from "../../hooks";
import PaginationComponent from "../../components/Pagination";
import { useSelector } from 'react-redux'
import { getFilterUsers } from "../../selectors/jobs";

const Bases = () => {

  const company = useSelector(state => state.company.company)
  const { register, handleSubmit, errors, setValue, reset  } = useForm({
    validateCriteriaMode: "all",
    mode: "onChange"
  });

  var date = moment().format('YYYY-MM-DD'); 
  const [bases, setBases] = useState([])
  const [type, setType] = useState(null)
  const [startDate, setStartDate] = useState(date)
  const [endDate, setEndDate] = useState(date)
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)
  const [update, setUpdate] = useState(false)
  const [pagination, setPagination] = useState({
    perPage: 50,
    page:1,
    pages:0
  });

  const toast = useToast();
  const { storeBase, getBasesUsersTypePaginationDate, changeBaseStatus, deleteBase, getUsers } = useApi();

  const getUserList = async () =>{
   
    const { response, error } = await getUsers();
    if (response) {
      const { content, message } = response.data;
      setUsers(getFilterUsers(content, company.id));
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }

  const getBasesListPageDate = async (paginationObject) =>{
    const type_id = type ? type : "null"
    const { response, error } = await getBasesUsersTypePaginationDate(paginationObject, user, type_id, startDate, endDate);
    if (response) {
      const { content, message } = response.data;
      setBases(content.data);
      setPagination({
        perPage: content.per_page,
        page: content.current_page,
        pages: content.last_page
      })
    } else if (error) {
        error.message && toast.error(error.message);
    }

  }
  
  const saveBase = async data => {
    if(!update){
      delete data.id;
    }
    const { response, error } = await storeBase(data);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      if(!update){
        getBasesListPageDate(pagination)
      }else{
        getBasesListPageDate(pagination)
        setUpdate(false)
      }
      reset();
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    } 
  }

  const changeStatusBase = async data => {
    
    const object = {
      id: data.id,
      status: data.status
    }
    const { response, error } = await changeBaseStatus(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      
      getBasesListPageDate(pagination)
      
    } else if (error) {
        error.message && toast.error(error.message);
    }
  }

  const deleteBaseRow = async data => {
    
    const object = {
      id: data.id
    }
    const { response, error } = await deleteBase(object);
    if (response) {
      //console.log(response);
      const { content, message } = response.data;
      toast.success(message);
      getBasesListPageDate(pagination)
      
    } else if (error) {
      if(error.response.status == 400){
        error.message && toast.error(error.response.data.message);
      }else{
        error.message && toast.error(error.message);
      } 
    }
  }

  const renderOptions = (list) => {
    return list.map((prop,key) => {
      return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
    });
  }

  const renderTable = (bases) => {
        let data = bases.map((prop, key) => {
            return  (
                 <tr key={prop.id}>
                    <th scope="row">{prop.id}</th>
                    <td>{prop.user.name}</td>
                    <td>{prop.type}</td>
                    <td>{prop.date}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>
                      {
                        prop.status == 1? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Activo
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          Eliminado
                        </Badge>
                      }
                     
                    </td>
                    <td className="text-left">
                    <UncontrolledDropdown>
                        <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={e => e.preventDefault()}
                        >
                            <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            {/* <DropdownItem
                                href="#"
                                onClick={() => fillBase(prop)}>Actualizar
                            </DropdownItem> */}
                            {/* <DropdownItem
                                href="#"
                                onClick={() => changeStatusBases(prop)}>Cambiar Estado
                            </DropdownItem> */}
                            <DropdownItem
                                href="#"
                                onClick={()=> deleteBaseRow(prop)}>Eliminar
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                
                </tr>
                
            )
        })
        return data;
  }
   
  const onPagination = (pagination)=>{
    getBasesListPageDate(pagination);
  }

  useEffect(()=>{
    getUserList();
  }, [])

  return (
    <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="shadow">
                        <CardHeader className="bg-white border-1">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Crear Base</h3>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={handleSubmit(saveBase)}>
                                <h6 className="heading-small text-muted mb-4">
                                    Información de la Base
                                </h6>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="6">
                                          <FormGroup>
                                            <label
                                              className="form-control-label"
                                              htmlFor="userId"
                                            >
                                              Trabajador
                                            </label>
                                            <Input
                                              className="form-control-alternative"
                                              id="userId"
                                              name="userId"
                                              placeholder="Seleccionar una opción"
                                              type="select"
                                              innerRef={register({ required: true })} >
                                                <option></option>
                                                {renderOptions(users)}
                                            </Input>
                                            {!!errors?.userId?.types?.required && <FormText>Campo requerido.</FormText>}
                                          </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                          <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="date">
                                                Fecha
                                            </label>
                                            <Input 
                                                name = "date"
                                                className="form-control-alternative"
                                                id="date"
                                                defaultValue={date}
                                                placeholder="Fecha"
                                                type="date"
                                                innerRef={register({ required: true})} />
                                            {!!errors?.date?.types?.required && <FormText>Campo requerido.</FormText>}
                                          </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="6">
                                        <FormGroup>
                                          <label
                                              className="form-control-label"
                                              htmlFor="value">
                                              Valor
                                          </label>
                                          <Input 
                                              name = "value"
                                              className="form-control-alternative"
                                              id="value"
                                              placeholder="Valor"
                                              type="number"
                                              innerRef={register({ required: true})} />
                                          {!!errors?.value?.types?.required && <FormText>Campo requerido.</FormText>}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="4">
                                          <FormGroup>
                                              <label
                                                  className="form-control-label"
                                                  htmlFor="type">
                                                  Tipo
                                              </label>
                                              <Input
                                                className="form-control-alternative"
                                                id="type"
                                                name="type"
                                                placeholder="Seleccionar una opción"
                                                type="select"
                                                innerRef={register({ required: true })} >
                                                  <option value="ANTERIOR">Anterior</option>
                                                  <option value="PAPELERIA">Papelería</option>
                                                  <option value="BARBA">Barba</option>
                                                  <option value="SOBRANTE">Sobrante</option> 
                                                  <option value="PAGADA">Pagada</option>
                                                  <option value="PENDIENTE">Pendiente</option> 
                                              </Input>
                                                  {!!errors?.type?.types?.required && <FormText>Campo requerido.</FormText>}
                                          </FormGroup>
                                      </Col>
                                      <Col lg="2">
                                            <div className="text-center">
                                                {
                                                !update ? 
                                                <Button className="my-4" color="primary" type="submit" >
                                                    Guardar
                                                </Button>
                                                :
                                                <>
                                                    <Button className="my-4" color="danger" type="button" onClick={()=>{reset(); setUpdate(false)}} >
                                                    Cancelar
                                                    </Button>
                                                    <Button className="my-4" color="info" type="submit" >
                                                        Actualizar datos
                                                    </Button>
                                                </>
                                                }
                                            </div>
                                        </Col>
                                        <Input
                                            className="form-control-alternative"
                                            hidden
                                            id="id"
                                            name="id"
                                            type="number"
                                            innerRef={register} 
                                        />

                                    </Row>
                                </div>
                            </Form>
            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
                    <Card className="shadow">
                        <CardHeader className="border-1">
                            <h3 className="mb-0">Lista de Bases</h3>
                        </CardHeader>

                        <CardBody>
                          
                          <Row>
                            <Col lg="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="startDate">
                                    Fecha Inicio
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="startDate"
                                  name="startDate"
                                  value={startDate}
                                  onChange={e=>setStartDate(e.target.value)}
                                  type="date" >
                                </Input>
                                          
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="endDate">
                                    Fecha Fin
                                  </label>
                                  <Input 
                                    name = "endDate"
                                    className="form-control-alternative"
                                    id="endDate"
                                    value={endDate}
                                    onChange={e=>setEndDate(e.target.value)}
                                    type="date" />
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="user"
                                  >
                                    Cobrador
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="user"
                                    name="user"
                                    placeholder="Seleccionar una opción"
                                    type="select"
                                    onChange={e=>setUser(e.target.value)} >
                                      <option></option>
                                      {renderOptions(users)}
                                  </Input>
                                </FormGroup>
                              </Col> 
                              <Col lg="2">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="type"
                                  >
                                    Tipo de Base
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="type"
                                    name="type"
                                    placeholder="Seleccionar una opción"
                                    type="select"
                                    onChange={e=>setType(e.target.value)} >
                                      <option></option>
                                      <option value="ANTERIOR">Anterior</option>
                                      <option value="PAPELERIA">Papelería</option>
                                      <option value="BARBA">Barba</option>
                                      <option value="SOBRANTE">Sobrante</option> 
                                      <option value="PAGADA">Pagada</option>
                                      <option value="PENDIENTE">Pendiente</option> 
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col lg="2">
                                <Button className="my-4" color="primary" type="submit" onClick={()=>getBasesListPageDate(pagination)}>
                                  Buscar
                                </Button>
                              </Col>                                   
                            </Row>
                        
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Código</th>
                                        <th scope="col">Trabajador</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Valor</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bases.length > 0 && renderTable(bases)}
                                </tbody>
                            </Table>
                          </CardBody>
                        <CardFooter className="py-4">
                            <PaginationComponent pagination={pagination} handlePage={onPagination}/>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        
        </Container>
    </>

  );
};

export default Bases;


