const headerContainer = {
    width: '100%',
    height: 64,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
}

const navbarToggleButton = {
    width: 30,
    height: 30,
    backgroundColor: '#1d1d1d',
    border: 'none',
}

const logoNavbar = {
    width: 40,
    height: 40,
    transitionProperty: 'display',
    transitionTimingFunction: 'linear',
    transitionDuration: '0.2s'
}

export {
    headerContainer,
    navbarToggleButton,
    logoNavbar
}