import React,{useState,useEffect,useRef} from 'react';
import moment from 'moment'
import { CURRENCY } from 'variables/app';
import { useReactToPrint } from 'react-to-print';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Table,
  } from "reactstrap";

  import { useApi, useToast } from "../../../hooks";
  import Header from 'components/Headers/Header';

  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../../selectors/jobs";

  
const DelayLoans = () => {

    const company = useSelector(state => state.company.company)
    var date = moment().format('YYYY-MM-DD'); 
    const [loans, setLoans] = useState([])
    const [users, setUsers] = useState([])
    const [quotes, setQuotes] = useState("all")
    const [userReport, setUserReport] = useState(null)
    const [to, setTo] = useState(500)
    const [from, setFrom] = useState(1)
    const toast = useToast();
    const { getUsers, getCustomersDelay } = useApi();

    useEffect(()=>{
        getUserList();
    }, [])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getUserReport = userId =>{
        let user = null
        for (let index = 0; index < users.length; index++) {
            if(users[index].id==userId){
                user =users[index]
            }
            
        }
        return user;
    }
    
    const getReportLoanData = async () =>{
        if(userReport!=null){
            const { response, error } = await getCustomersDelay(userReport, `${from}-${to}`);
            if (response) {
                const { content, message } = response.data;
                setLoans(content)

            } else if (error) {
                error.message && toast.error(error.message);
            }
        }
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(getFilterUsers(content, company.id));
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }


    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    
    const renderTableReport = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id}>
                     <td>{i}</td>
                     <td>{prop.customer.name}</td>
                     <td>{prop.startDate}</td>
                     <td>{prop.endDate}</td>
                    <td>{CURRENCY.format(prop.quota)}</td>
                    <td>{CURRENCY.format(prop.total)}</td>
                    <td>{CURRENCY.format(prop.total-prop.balance)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{Math.trunc(prop.delay_count)}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte por día
                                        </h6>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="userId"
                                                    >
                                                    Usuario
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="userId"
                                                    name="userId"
                                                    placeholder="Seleccionar una opción"
                                                    type="select"
                                                    onChange={e=>setUserReport(e.target.value)}>
                                                        <option></option>
                                                        {renderOptions(users)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="1">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="fromQuotes"
                                                    >
                                                        Cuotas Desde
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="fromQuotes"
                                                    name="tfromQuotesoQuotes"
                                                    defaultValue={from}
                                                    type="number"
                                                    step={1}
                                                    onChange={e=>setFrom(e.target.value)}/>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="1">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="toQuotes"
                                                    >
                                                        Cuotas Hasta
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="toQuotes"
                                                    name="toQuotes"
                                                    defaultValue={to}
                                                    type="number"
                                                    step={1}
                                                    onChange={e=>setTo(e.target.value)}/>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>{getReportLoanData()}}>
                                                    Consultar
                                                </Button>
                                            </Col>     
                                            <Col lg="1">
                                                <Button className="my-4" color="secundary" type="info" onClick={handlePrint}>Imprimir</Button>
                                            </Col>                              
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <PrintComponent username={userReport? getUserReport(userReport).name:""} loans={loans} ref={componentRef} />
                            </CardBody>
                        </Card>
                    </Col>
            
                </Row>
            </Container>
            
            
        </>
    );
};

class PrintComponent extends React.Component {
    renderTableReport = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id}>
                     <td>{i}</td>
                     <td>{prop.customer.name}</td>
                     <td>{prop.startDate}</td>
                     <td>{prop.endDate}</td>
                    <td>{CURRENCY.format(prop.quota)}</td>
                    <td>{CURRENCY.format(prop.total)}</td>
                    <td>{CURRENCY.format(prop.total-prop.balance)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{Math.trunc(prop.delay_count)}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }
    render() {
        return (
            <>
                <div>
                    <Row>
                        <h6 className="heading-small text-muted mb-4">
                            Prestamos Con cuotas atrasadas - {this.props.username}
                        </h6>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Fecha de prestamo</th>
                                    <th scope="col">Fecha Límite</th>
                                    <th scope="col">Cuota</th>
                                    <th scope="col">Prestado</th>
                                    <th scope="col">Abonado</th>
                                    <th scope="col">Saldo</th>
                                    <th scope="col">Atraso</th>
                                    <th scope="col">Prestamo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.loans.length > 0 && this.renderTableReport(this.props.loans)}
                            </tbody>
                        </Table> 
                    </Row>
                    
                </div>
            </>
            
        );
    }
}

export default DelayLoans;