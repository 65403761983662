import React,{useState,useEffect,useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import { CURRENCY } from 'variables/app';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Badge,
    Table,
    CardTitle
  } from "reactstrap";

  import { useApi, useToast } from "../../../hooks";
  import Header from 'components/Headers/Header';

  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../../selectors/jobs";

  
const Balance = () => {

    const company = useSelector(state => state.company.company)
    var date = moment().format('YYYY-MM-DD'); 
    const [loans, setLoans] = useState([])
    const [dataReport, setDataReport] = useState({
        loans:[],
        total: 0
    })
    const [users, setUsers] = useState([])
    const [dateReport, setDate] = useState(date)
    const [userReport, setUserReport] = useState(null)
    const toast = useToast();
    const { getUsers, getReportDaySale } = useApi();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(()=>{
        getUserList();
    }, [])
    
    const getReportLoanData = async () =>{
        if(userReport!=null){
            const { response, error } = await getReportDaySale(dateReport, userReport);
            if (response) {
                const { content, message } = response.data;
                setDataReport(content)

            } else if (error) {
                error.message && toast.error(error.message);
            }
        }
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(getFilterUsers(content, company.id));
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }
    
    const renderTableReport = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id} style={{
                     color:"white",
                     fontWeight:"bold"
                 }} className={prop.value_quota > 0 ? 'bg-gradient-green' :'bg-gradient-red'}>
                     <td>{i}</td>
                     <td>{prop.customer.name}</td>
                    <td>{
                        prop.value_quota > 0? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Abonó
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          No Abonó
                        </Badge>
                      }</td>
                    <td>{CURRENCY.format(prop.value_quota)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{prop.delay_count}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }

    const renderTableReportSale = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id} className={prop.value_quota > 0? 'bg-gradient-green' :'bg-gradient-blue'}>
                    <td>{i}</td>
                    <td>{prop.customer.name}</td>
                    <td>{
                        prop.value_quota > 0? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Abonó
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          No Abonó
                        </Badge>
                      }</td>
                    <td>{prop.value_quota}</td>
                    <td>{prop.balance}</td>
                    <td>{0}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte por día
                                        </h6>
                                        <Row>
                                            <Col lg="4">
                                            <FormGroup>
                                                <label
                                                className="form-control-label"
                                                htmlFor="dateReport">
                                                    Fecha
                                                </label>
                                                <Input
                                                className="form-control-alternative"
                                                id="dateReport"
                                                name="dateReport"
                                                value={dateReport}
                                                onChange={e=>setDate(e.target.value)}
                                                type="date" >
                                                </Input>
                                                        
                                            </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="userId"
                                                    >
                                                    Usuario
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="userId"
                                                    name="userId"
                                                    placeholder="Seleccionar una opción"
                                                    type="select"
                                                    onChange={e=>setUserReport(e.target.value)}>
                                                        <option></option>
                                                        {renderOptions(users)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="1">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>{getReportLoanData()}}>
                                                    Consultar
                                                </Button>
                                            </Col>        
                                            <Col lg="1">
                                                {dataReport.loans.length >0 && <Button className="my-4" color="info" type="submit" onClick={handlePrint}>Imprimir</Button>}
                                            </Col>                              
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <PrintComponent dataReport={dataReport}  ref={componentRef} />
                            </CardBody>
                        </Card>
                    </Col>
            
                </Row>
            </Container>
        </>
    );
};

class PrintComponent extends React.Component {

    renderTableReport = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id} className={prop.value_quota > 0 ? 'bg-gradient-green rowReport' :'bg-gradient-red rowReport'}>
                     <td>{i}</td>
                     <td>{prop.customer.name}</td>
                    <td>{
                        prop.value_quota > 0? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Abonó
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          No Abonó
                        </Badge>
                      }</td>
                    <td>{CURRENCY.format(prop.value_quota)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{Math.trunc(prop.delay_count)}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }

    render() {
        return(
            <>
                <Row>
                    <Col xl="12">
                        <Card className="bg-gradient-default">
                            <CardBody>
                                <CardTitle className="text-white">Total</CardTitle>
                                <span className="h3 font-weight-bold mb-0 text-white">{CURRENCY.format(this.props.dataReport.total)}</span>
                            </CardBody>
                        </Card>
                    </Col>
               
                    <br/>
                    <h6 className="heading-small text-muted mb-4">
                        Prestamos Activos
                    </h6>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Cuota</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">Atraso</th>
                                <th scope="col">Prestamo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.dataReport.loans.length > 0 && this.renderTableReport(this.props.dataReport.loans)}
                        </tbody>
                    </Table>
                </Row>
            </>
        );
    }
}

export default Balance;