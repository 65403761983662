import React,{useState,useEffect, useRef} from 'react';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import { CURRENCY } from 'variables/app';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Badge,
    Table,
    CardTitle
  } from "reactstrap";

  import { useApi, useToast } from "../../../hooks";
  import Header from 'components/Headers/Header';
  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../../selectors/jobs";
  
const Day = () => {

    const company = useSelector(state => state.company.company)
    var date = moment().format('YYYY-MM-DD'); 
    const [loans, setLoans] = useState([])
    const [saleLoans, setSaleLoans] = useState([])
    const [isActiveLoans, setIsActiveLoans] = useState(true)
    const [totalActive, setTotalActive] = useState(0)
    const [totalSale, setTotalSale] = useState(0)
    const [users, setUsers] = useState([])
    const [dateReport, setDate] = useState(date)
    const [userReport, setUserReport] = useState(null)
    const toast = useToast();
    const { getUsers, getReportLoan, getPdfUrl } = useApi();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(()=>{
        getUserList();
    }, [])

    const getUserReport = userId =>{
        let user = null
        for (let index = 0; index < users.length; index++) {
            if(users[index].id==userId){
                user =users[index]
            }
            
        }
        return user;
    }
    
    const getReportLoanData = async () =>{
        if(userReport!=null){
            const { response, error } = await getReportLoan(dateReport, userReport);
            if (response) {
                const { content, message } = response.data;
                setLoans(content.activeLoans)
                setSaleLoans(content.saleLoans)
                setTotalActive(content.totalActive)
                setTotalSale(content.totalSale)

            } else if (error) {
                error.message && toast.error(error.message);
            }
        }
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(getFilterUsers(content, company.id));
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }


    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    
    const renderTableReport = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id} style={{
                     color:"white",
                     fontWeight:"bold"
                 }} className={prop.value_quota > 0 ? 'bg-gradient-green' :'bg-gradient-red'}>
                    <td>{i}</td>
                    <td>{prop.customer.name}</td>
                    <td>{
                        prop.value_quota > 0? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Abonó
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          No Abonó
                        </Badge>
                      }</td>
                    <td>{ prop.value_quota >0 && CURRENCY.format(prop.value_quota) }</td>
                    <td>{ CURRENCY.format(prop.balance_day) }</td>
                    <td>{
                        prop.payment_mode.name == "Semanal" ? Math.round(prop.delay_count * 7) : 
                        prop.payment_mode.name == "Mensual" ? Math.round(prop.delay_count * 30) :
                        prop.delay_count
                    }</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }

    const renderTableReportSale = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id} style={{
                    color:"white",
                    fontWeight:"bold"
                    }} className={prop.value_quota > 0? 'bg-gradient-green' :'bg-gradient-blue'}>
                    <td>{i}</td>
                    <td>{prop.customer.name}</td>
                    <td>{
                        prop.value_quota > 0? 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-success" />
                          Abonó
                        </Badge> : 
                        <Badge color="" className="badge-dot mr-4">
                          <i className="bg-warning" />
                          No Abonó
                        </Badge>
                      }</td>
                    <td>{prop.value_quota}</td>
                    <td>{prop.balance_day}</td>
                    <td>{0}</td>
                    <td>{prop.payment_mode.name}</td>
                </tr>
                
            )
        })
        return data;
    }

    const printPlanilla = () =>{
        window.open(getPdfUrl(userReport, dateReport), '_blank');
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte por día
                                        </h6>
                                        <Row>
                                            <Col lg="4">
                                            <FormGroup>
                                                <label
                                                className="form-control-label"
                                                htmlFor="dateReport">
                                                    Fecha
                                                </label>
                                                <Input
                                                className="form-control-alternative"
                                                id="dateReport"
                                                name="dateReport"
                                                value={dateReport}
                                                onChange={e=>setDate(e.target.value)}
                                                type="date" >
                                                </Input>
                                                        
                                            </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="userId"
                                                    >
                                                    Usuario
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="userId"
                                                    name="userId"
                                                    placeholder="Seleccionar una opción"
                                                    type="select"
                                                    onChange={e=>setUserReport(e.target.value)}>
                                                        <option></option>
                                                        {renderOptions(users)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="1">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>{getReportLoanData()}}>
                                                    Consultar
                                                </Button>
                                            </Col>   
                                            <Col lg="1">
                                                {loans.length >0 && <Button className="my-4" color="secundary" type="info" onClick={handlePrint}>Imprimir</Button>}
                                            </Col>    
                                            <Col lg="1">
                                                {loans.length >0 && <Button className="my-4" color="secundary" type="info" onClick={() =>printPlanilla()}>Planilla</Button>}
                                            </Col>                            
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody >
                                <Row>
                                    <Col xl="4">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Total Recaudo</CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(totalActive)}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="4">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Total Paz y Salvo</CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(totalSale)}</span>
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="4">
                                        <Card className="bg-gradient-primary">
                                            <CardBody>
                                                <CardTitle>Total GENERAL</CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(totalActive+totalSale)}</span>
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <PrintComponent loans={loans} saleLoans={saleLoans} totalActive={totalActive} totalSale={totalSale} dateReport={dateReport} username={userReport? getUserReport(userReport).name:""}  ref={componentRef} />
                                
                                {/* <Row>
                                    <Col xl="6">
                                        <Card className="bg-gradient-default">
                                            <CardBody>
                                                <CardTitle className="text-white">Total Activos</CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(totalActive)}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="6">
                                        <Card className="bg-gradient-primary">
                                            <CardBody>
                                                <CardTitle>Total Paz y Salvo</CardTitle>
                                                <span className="h4 font-weight-bold mb-0 text-white">{CURRENCY.format(totalSale)}</span>
                                                
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <br/>
                                <h6 className="heading-small text-muted mb-4">
                                    Prestamos Activos
                                </h6>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Cliente</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Cuota</th>
                                            <th scope="col">Saldo</th>
                                            <th scope="col">Atraso</th>
                                            <th scope="col">Prestamo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loans.length > 0 && renderTableReport(loans)}
                                    </tbody>
                                </Table>
                            
                                <br/>
                                <h6 className="heading-small text-muted mb-4">
                                    Prestamos Paz y Salvo
                                </h6>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Cliente</th>
                                            <th scope="col">Estado</th>
                                            <th scope="col">Cuota</th>
                                            <th scope="col">Saldo</th>
                                            <th scope="col">Atraso</th>
                                            <th scope="col">Prestamo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {saleLoans.length > 0 && renderTableReportSale(saleLoans)}
                                    </tbody>
                                </Table> */}
                                
                            </CardBody>
                        </Card>
                    </Col>
            
                </Row>
            </Container>
            
            
        </>
    );
};

class PrintComponent extends React.Component {
    
    renderTableReport = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id} className={prop.value_quota > 0 ? 'bg-gradient-green rowReport' :'bg-gradient-red rowReport'}>
                    <td>{i}</td>
                    <td>{prop.customer.name}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>{ prop.value_quota >0 && CURRENCY.format(prop.value_quota) }</td>
                    <td>{ `${CURRENCY.format(parseInt(prop.avg_quota))} - ${((parseInt(prop.avg_quota)/parseInt(prop.quota))*100).toFixed(0)}%` }</td>
                    <td>{ CURRENCY.format(prop.total - prop.balance_day) }</td>
                    <td>{
                        prop.payment_mode.name == "Semanal" ? Math.round(prop.delay_count * 7) : 
                        prop.payment_mode.name == "Mensual" ? Math.round(prop.delay_count * 30) :
                        Math.round(prop.delay_count)
                    }</td>
                    
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light rowReport">
                <th colSpan="3" scope="col">TOTAL</th>
                <th colSpan="4" scope="col">{CURRENCY.format(this.props.totalActive)}</th>
            </tr>
        )
        return data;
    }

    renderTableReportSale = (loans) => {
        let i = 0;
        let data = loans.map((prop, key) => {
            i++;
            return  (
                 <tr key={prop.id}  className={prop.value_quota > 0? 'bg-gradient-green rowReport' :'bg-gradient-blue rowReport'}>
                    <td>{i}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>{prop.value_quota > 0? 'Abonó' : 'No Abonó'}</td>
                    <td>{CURRENCY.format(prop.value_quota)}</td>
                    <td>{CURRENCY.format(prop.total - prop.balance_day)}</td>
                    <td>{0}</td>
                    
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light rowReport">
                <th colSpan="3" scope="col">TOTAL</th>
                <th colSpan="4" scope="col">{CURRENCY.format(this.props.totalSale)}</th>
            </tr>
        )
        return data;
    }

    render() {
        
        return(
            <>
                <Row>
                    <h6 className="heading-small text-muted mb-4">
                        Prestamos Activos - {this.props.username} - {this.props.dateReport}
                    </h6>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Prestamo</th>
                                <th scope="col">Cuota</th>
                                <th scope="col">Promedio</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">Atraso</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.loans.length > 0 && this.renderTableReport(this.props.loans)}
                        </tbody>
                    </Table>
                
                    <br/>
                    <br/>
                    <h6 className="heading-small text-muted mb-4">
                        Prestamos Paz y Salvo - {this.props.username} - {this.props.dateReport}
                    </h6>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Prestamo</th>
                                <th scope="col">Cuota</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">Atraso</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.saleLoans.length > 0 && this.renderTableReportSale(this.props.saleLoans)}
                        </tbody>
                    </Table>
                </Row>

                
            </>
        );
    }
}

export default Day;