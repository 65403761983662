import React,{useState,useEffect, useRef} from 'react';
import moment from 'moment'
import { CURRENCY } from 'variables/app';
import { useReactToPrint } from 'react-to-print';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Badge,
    Table,
    CardTitle
  } from "reactstrap";

  import Header from 'components/Headers/Header';
  import { useApi, useToast } from "../../../hooks";

  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../../selectors/jobs";

  
const GeneralDetail = () => {

    const company = useSelector(state => state.company.company)
    var date = moment().format('YYYY-MM-DD'); 
    const [users, setUsers] = useState([])
    const [startDate, setStartDate] = useState(date)
    const [endDate, setEndDate] = useState(date)
    const [user, setUser] = useState(null)
    const [reportData, setReportData] = useState({
        expenses:{
            quantity:0,
            total:0,
            data:[]
        },
        loans:{
            quantity:0,
            total:0,
        }
    })

    const toast = useToast();
    const { getUsers, getDetailReportGeneral } = useApi();

    useEffect(()=>{
        getUserList();
    }, [])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getUserReport = userId =>{
        let user = null
        for (let index = 0; index < users.length; index++) {
            if(users[index].id==userId){
                user =users[index]
            }
            
        }
        return user;
    }
    
    const getReportData = async () =>{
        if(user!=null){
            const { response, error } = await getDetailReportGeneral(startDate, endDate, user);
            if (response) {
                const { content, message } = response.data;
                setReportData(content)
            } else if (error) {
                error.message && toast.error(error.message);
            }
        }
    }

    const getUserList = async () =>{
    
        const { response, error } = await getUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(getFilterUsers(content, company.id));
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const renderOptions = (list) => {
        return list.map((prop,key) => {
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
    }

    const renderTableExpenses = (expenses) => {
        let i = 0;
        let total = 0;
        let data = expenses.map((prop, key) => {
            i++;
            total += parseInt(prop.value)
            return  (
                 <tr key={prop.id}>
                    <td>{i}</td>
                    <td>{prop.detail}</td>
                    <td>{prop.expense_type.name}</td>
                    <td>{prop.date}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light">
                <th colSpan="4" scope="col">TOTAL</th>
                <th scope="col">{CURRENCY.format(total)}</th>
            </tr>
        )
        
        return data;
    }

    const renderTableLoans = (loans) => {
        let i = 0;
        let totalSell = 0
        let totalPays = 0
        let totalSale = 0
        let totalUtility = 0
        let totalRealUtility = 0
        let data = loans.map((prop, key) => {
            i++;
            const pays = parseInt(prop.total) - parseInt(prop.balance)
            const utility = parseInt(prop.total) - parseInt(prop.value)
            const realUtility = parseInt(prop.balance) <=0 ? parseInt(prop.total) - parseInt(prop.balance) : 0
            totalSell += parseInt(prop.value)
            totalPays +=pays
            totalSale += parseInt(prop.balance)
            totalUtility += utility
            
            totalRealUtility += realUtility

            return  (
                
                 <tr key={prop.id}>
                    <td>{i}</td>
                    <td>{prop.startDate}</td>
                    <td>{prop.customer.name}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>{CURRENCY.format(pays)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{CURRENCY.format(utility)}</td>
                    <td>{CURRENCY.format(realUtility)}</td>
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light">
                <th colSpan="3" scope="col"></th>
                <th scope="col">Venta</th>
                <th scope="col">Recaudo</th>
                <th scope="col">Saldo</th>
                <th scope="col">Utilidad Proyectada</th>
                <th scope="col">Utilidad real</th>
            </tr>
        )
        //console.log(totalSell)
        data.push(
            <tr key="ts1">
                <td colSpan="3" >Totales</td>
                <td>{CURRENCY.format(totalSell)}</td>
                <td>{CURRENCY.format(totalPays)}</td>
                <td>{CURRENCY.format(totalSale)}</td>
                <td>{CURRENCY.format(totalUtility)}</td>
                <td>{CURRENCY.format(totalRealUtility)}</td>
            </tr>
        )
        return data;
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte General Detallado
                                        </h6>
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="startDate">
                                                        Fecha Inicio
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="startDate"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={e=>{setStartDate(e.target.value)}}
                                                    type="date" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="endDate">
                                                        Fecha Fin
                                                    </label>
                                                    <Input 
                                                        name = "endDate"
                                                        className="form-control-alternative"
                                                        id="endDate"
                                                        value={endDate}
                                                        onChange={e=>{setEndDate(e.target.value)}}
                                                        type="date" />
                                                </FormGroup>
                                            </Col> 
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="userId"
                                                    >
                                                    Usuario
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="userId"
                                                    name="userId"
                                                    placeholder="Seleccionar una opción"
                                                    type="select"
                                                    onChange={e=>setUser(e.target.value)}>
                                                        <option></option>
                                                        {renderOptions(users)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>getReportData()}>
                                                    Consultar
                                                </Button>
                                            </Col>         
                                            <Col lg="1">
                                                <Button className="my-4" color="secundary" type="info" onClick={handlePrint}>Imprimir</Button>
                                            </Col>                              
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <PrintComponent startDate={startDate} endDate={endDate} reportData={reportData} username={user? getUserReport(user).name:""} ref={componentRef} />
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </Container>
        </>
    );
};

class PrintComponent extends React.Component {
    renderTableExpenses = (expenses) => {
        let i = 0;
        let total = 0;
        let data = expenses.map((prop, key) => {
            i++;
            total += parseInt(prop.value)
            return  (
                 <tr key={prop.id}>
                    <td>{i}</td>
                    <td>{prop.detail}</td>
                    <td>{prop.expense_type.name}</td>
                    <td>{prop.date}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light">
                <th colSpan="4" scope="col">TOTAL</th>
                <th scope="col">{CURRENCY.format(total)}</th>
            </tr>
        )
        
        return data;
    }

    renderTableLoans = (loans) => {
        let i = 0;
        let totalSell = 0
        let totalPays = 0
        let totalSale = 0
        let totalUtility = 0
        let totalRealUtility = 0
        let data = loans.map((prop, key) => {
            i++;
            const pays = parseInt(prop.total) - parseInt(prop.balance)
            const utility = parseInt(prop.total) - parseInt(prop.value)
            const realUtility = parseInt(prop.balance) <=0 ? parseInt(prop.total) - parseInt(prop.balance) : 0
            totalSell += parseInt(prop.value)
            totalPays +=pays
            totalSale += parseInt(prop.balance)
            totalUtility += utility
            
            totalRealUtility += realUtility

            return  (
                
                 <tr key={prop.id}>
                    <td>{i}</td>
                    <td>{prop.startDate}</td>
                    <td>{prop.customer.name}</td>
                    <td>{CURRENCY.format(prop.value)}</td>
                    <td>{CURRENCY.format(pays)}</td>
                    <td>{CURRENCY.format(prop.balance)}</td>
                    <td>{CURRENCY.format(utility)}</td>
                    <td>{CURRENCY.format(realUtility)}</td>
                </tr>
                
            )
        })
        data.push(
            <tr className="thead-light">
                <th colSpan="3" scope="col"></th>
                <th scope="col">Venta</th>
                <th scope="col">Recaudo</th>
                <th scope="col">Saldo</th>
                <th scope="col">Utilidad Proyectada</th>
                <th scope="col">Utilidad real</th>
            </tr>
        )
        //console.log(totalSell)
        data.push(
            <tr key="ts1">
                <td colSpan="3" >Totales</td>
                <td>{CURRENCY.format(totalSell)}</td>
                <td>{CURRENCY.format(totalPays)}</td>
                <td>{CURRENCY.format(totalSale)}</td>
                <td>{CURRENCY.format(totalUtility)}</td>
                <td>{CURRENCY.format(totalRealUtility)}</td>
            </tr>
        )
        return data;
    }

    render(){
        return(
        <>
            <div>
                <Row>
                    <h6 className="heading-small text-muted mb-4">
                        Ventas - {this.props.username} - Fecha: {this.props.startDate} a {this.props.endDate}
                    </h6>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Cliente</th>
                                <th scope="col">Venta</th>
                                <th scope="col">Recaudo</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">Utilidad Proyectada</th>
                                <th scope="col">Utilidad real</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.reportData.loans.length > 0 && this.renderTableLoans(this.props.reportData.loans)}
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <h6 className="heading-small text-muted mb-4">
                        Gastos - {this.props.username} - Fecha: {this.props.startDate} a {this.props.endDate}
                    </h6>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Detalle</th>
                                <th scope="col">Tipo de Gasto</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.reportData.expenses.length > 0 && this.renderTableExpenses(this.props.reportData.expenses)}
                        </tbody>
                    </Table>
                </Row>
            </div>
            
        </>);
    }
}

export default GeneralDetail;