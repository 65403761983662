import { useSelector } from 'react-redux';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from 'store/actions';

function useApi(){
    const token = useSelector(state => state.auth?.user?.token);
    const history = useHistory();
    const dispatch = useDispatch();

    //const base_url = 'http://localhost:8000/api/';
    const base_url = 'https://server.gestionappempresas.com/public/api/';
    //const base_url = 'http://server.inversionesjose.com/public/api/';
    //const base_url = 'https://server.gestionarlp.com/public/api/';
    //const base_url = 'http://server.gestionarlr.com/public/api/';
    //const base_url = 'http://server.inversioneslapatrona.co/public/api/';
    //const base_url = 'http://apigestionapp.ingjosejimenez.com/public/api/';

    axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        //console.log(error);
        if(error.message.search('401') > 0){
            
            dispatch(authActions.logout());
            history.push('/auth/login');
        }else return Promise.reject(error);
       
      });

    const fetcher = (type, url, data, contentType) => {

        const headers = {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        if(contentType == 'multipart/form-data')
            data = makeMultiPart(data);

        headers['Content-Type'] = contentType || 'application/json';
        if(token) headers.Authorization = `Bearer ${token}`;
       
        return axios({
            method: type,
            url: `${base_url}${url}`,
            data: data ? data : {},
            headers
        }).then(response => ({response})).catch(error => ({error}));
    };

    const fetcherWithParams = (type, url, params, data, contentType) => {

        const headers = {
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        headers['Content-Type'] = contentType || 'application/json';
        if(token) headers.Authorization = `Bearer ${token}`;
        return axios({
            params,
            method: type,
            url: `${base_url}${url}`,
            data: data ? data : {},
            headers
        }).then(response => ({response})).catch(error => ({error}));
    };

    const makeMultiPart =(data) => {
        let formData = new FormData();
        Object.entries(data).forEach(item =>{
            if(item[0] == 'image' || item[0] == 'pdf'){
                formData.append(item[0], item[1][0]);
            }else{
                formData.append(item[0], item[1]);
            }
            
        });
        return formData;
    }

    const authUser = (user) => fetcher('post', 'login', user);

    const storeUser = (user) => fetcher('post', 'user', user);
    const getUsers = () => fetcher('get', 'user');
    const getAllUsers = () => fetcher('get', 'all/user');
    const getUsersPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/user`, {page: pagination.page});
    const changeUserStatus = (person) => fetcher('post', 'user/status', person);
    const deleteUser = (person) => fetcher('post', 'delete/user', person);
    const getSupervisors = () => fetcher('get', 'supervisor');

    //CUSTOMERS
    const storeCustomer = (customer) => fetcher('post', 'customer', customer);
    const changeCustomerStatus = (customer) => fetcher('post', 'customer/status', customer);
    const deleteCustomer = (customer) => fetcher('post', 'delete/customer', customer);
    const getCustomers = () => fetcher('get', 'customer');
    const getCustomersPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/customer`, {page: pagination.page});
    const getCustomersByUserPagination = (pagination, user, report, name) => fetcherWithParams('get', `perpage/${pagination.perPage}/user/${user}/report/${report}/name/${name}/customerbyowner`, {page: pagination.page});
    const getCustomersDelay = (user, days) => fetcher('get', `user/${user}/delay/${days}/customer`);
    
    //COMPANY
    const storeCompany = (company) => fetcher('post', 'company', company);
    const changeCompanyStatus = (company) => fetcher('post', 'company/status', company);
    const deleteCompany = (company) => fetcher('post', 'delete/company', company);
    const getCompanies = () => fetcher('get', 'company');
    const getCompaniesByOwner = (owner) => fetcher('get', `owner/company`);
    const getCompaniesPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/company`, {page: pagination.page});

     //OWNER
     const storeOwner = (owner) => fetcher('post', 'owner', owner);
     const changeOwnerStatus = (owner) => fetcher('post', 'owner/status', owner);
     const deleteOwner = (owner) => fetcher('post', 'delete/owner', owner);
     const getOwners = () => fetcher('get', 'owner');
     const getOwnersPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/owner`, {page: pagination.page});

    //ROL
    const storeRol = (rol) => fetcher('post', 'rol', rol);
    const changeRolStatus = (rol) => fetcher('post', 'rol/status', rol);
    const deleteRol = (rol) => fetcher('post', 'delete/rol', rol);
    const getRoles = () => fetcher('get', 'rol');
    const getRolesPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/rol`, {page: pagination.page});

    //BASE
    const storeBase = (base) => fetcher('post', 'base', base);
    const changeBaseStatus = (base) => fetcher('post', 'base/status', base);
    const deleteBase = (base) => fetcher('post', 'delete/base', base);
    const getBases = () => fetcher('get', 'base');
    const getBasesPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/base`, {page: pagination.page});
    const getBasesPaginationDate = (pagination,startdate,enddate) => fetcherWithParams('get', `perpage/${pagination.perPage}/startdate/${startdate}/enddate/${enddate}/base`, {page: pagination.page});
    const getBasesUserPaginationDate = (pagination,userId,startdate,enddate) => fetcherWithParams('get', `perpage/${pagination.perPage}/user/${userId}/startdate/${startdate}/enddate/${enddate}/base`, {page: pagination.page});

    //INJECTION
    const storeInjection = (injection) => fetcher('post', 'injection', injection);
    const changeInjectionStatus = (injection) => fetcher('post', 'injection/status', injection);
    const deleteInjection = (injection) => fetcher('post', 'delete/injection', injection);
    const getInjections = () => fetcher('get', 'injection');
    const getInjectionsPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/injection`, {page: pagination.page});
    const getInjectionsPaginationDate = (pagination,startdate,enddate) => fetcherWithParams('get', `perpage/${pagination.perPage}/startdate/${startdate}/enddate/${enddate}/injection`, {page: pagination.page});
    const getInjectionsUserPaginationDate = (pagination,userId,startdate,enddate) => fetcherWithParams('get', `perpage/${pagination.perPage}/user/${userId}/startdate/${startdate}/enddate/${enddate}/injection`, {page: pagination.page});
    const getBasesUsersTypePaginationDate = (pagination,userId,type,startdate,enddate) => fetcherWithParams('get', `perpage/${pagination.perPage}/user/${userId}/type/${type}/startdate/${startdate}/enddate/${enddate}/base`, {page: pagination.page});

    //EXPENSE
    const storeExpense = (expense) => fetcher('post', 'expense', expense);
    const changeExpenseStatus = (expense) => fetcher('post', 'expense/status', expense);
    const deleteExpense = (expense) => fetcher('post', 'delete/expense', expense);
    const getExpenses = () => fetcher('get', 'expense');
    const getExpensesPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/expense`, {page: pagination.page});
    const getExpensesPaginationDate = (pagination,startdate,enddate) => fetcherWithParams('get', `perpage/${pagination.perPage}/startdate/${startdate}/enddate/${enddate}/expense`, {page: pagination.page});
    const getExpensesUsersPaginationDate = (pagination,userId,startdate,enddate) => fetcherWithParams('get', `perpage/${pagination.perPage}/user/${userId}/startdate/${startdate}/enddate/${enddate}/expense`, {page: pagination.page});

    //EXPENSETYPE
    const storeExpenseType = (expenseType) => fetcher('post', 'expenseType', expenseType);
    const changeExpenseTypeStatus = (expenseType) => fetcher('post', 'expenseType/status', expenseType);
    const deleteExpenseType = (expenseType) => fetcher('post', 'delete/expenseType', expenseType);
    const getExpenseTypes = () => fetcher('get', 'expenseType');
    const getExpenseTypesPagination = (pagination) => fetcherWithParams('get', `perpage/${pagination.perPage}/expenseType`, {page: pagination.page});

    //EXPENSETYPE
    const storeSale = (sale) => fetcher('post', 'sale', sale);

    //REPORT
    const getReportSale = (startdate,enddate,userid) => fetcher('get', `report/sale/date/startdate/${startdate}/enddate/${enddate}/user/${userid}`);
    const getReportLoan = (date,userid) => fetcher('get', `user/${userid}/date/${date}/report/loan`);
    const getReportLoanSale = (date,userid) => fetcher('get', `user/${userid}/date/${date}/report/loan/sale`);
    const getReportGeneral = (startdate,enddate) => fetcher('get', `report/general/date/startdate/${startdate}/enddate/${enddate}`);
    const getChartSale = (startdate,enddate) => fetcher('get', `report/chart/sale/startdate/${startdate}/enddate/${enddate}`);
    const getReportLoandate = (startDate, endDate,userid) => fetcher('get', `user/${userid}/startdate/${startDate}/enddate/${endDate}/report/loan`);
    const getReportDaySale = (date,userid) => fetcher('get', `report/day/sale/user/${userid}/date/${date}`);
    const getReportLogdate = (startDate, endDate,userid) => fetcher('get', `user/${userid}/startdate/${startDate}/enddate/${endDate}/logs`);

    const getDetailReportGeneral = (startdate,enddate, user) => fetcher('get', `report/detail/general/user/${user}/date/startdate/${startdate}/enddate/${enddate}`);

    //PAYMENTMODE
    const getPaymentsMode = () => fetcher('get', 'paymentMode');

    //LOAN
    const storeLoan = (loan) => fetcher('post', 'admin/loan', loan);
    const updateLoan = (loan) => fetcher('post', 'update/admin/loan', loan);
    const getLoansByCustomer = (customer) => fetcher('get', `customer/${customer}/loan`);
    const getActiveLoanByCustomer = (customer) => fetcher('get', `customer/${customer}/active/loan`);
    const changeLoanStatus = (loan) => fetcher('post', 'loan/status', loan);
    const storeFinalSale = (finalSale) => fetcher('post', 'finalsale', finalSale);
    const deleteFinalSale = (finalSale) => fetcher('post', 'delete/finalsale', finalSale);
    const deleteFinalSalePayment = (finalSale) => fetcher('post', 'delete/payment', finalSale);
    const getFinalSalePayment = (user,date) => fetcher('get', `user/${user}/date/${date}/payment`);

    //QUOTE
    const storeQuote = (quote) => fetcher('post', 'admin/quote', quote);
    const getQuotesByLoans = (loan) => fetcher('get', `loan/${loan}/quote`);
    const deleteQuote = (quote) => fetcher('post', 'delete/quote', quote);
    const getBadQuotes = (user_id,date) => fetcher('get', `user/${user_id}/date/${date}/badquotes`);
    
    //BACKUP
    const getBackup = () => fetcher('get', 'backup');

    const getPdfUrl = (userId, date) => `${base_url}user/${userId}/date/${date}/pdf`

    return {
        getPdfUrl,
        fetcher, authUser, 
        storeUser, getUsers, getUsersPagination, changeUserStatus,  deleteUser, getAllUsers, getSupervisors,
        storeRol, changeRolStatus, deleteRol, getRoles, getRolesPagination,
        storeCustomer, changeCustomerStatus, deleteCustomer, getCustomers, getCustomersPagination, getCustomersByUserPagination, getCustomersDelay,
        storeCompany, changeCompanyStatus, deleteCompany, getCompanies, getCompaniesPagination,
        storeRol, changeRolStatus, deleteRol, getRoles, getRolesPagination,
        storeBase, changeBaseStatus, deleteBase, getBases, getBasesPagination, getBasesPaginationDate, getBasesUserPaginationDate,getBasesUsersTypePaginationDate,
        storeInjection, changeInjectionStatus, deleteInjection, getInjections, getInjectionsPagination, getInjectionsPaginationDate, getInjectionsUserPaginationDate,
        storeExpense, changeExpenseStatus, deleteExpense, getExpenses, getExpensesPagination, getExpensesPaginationDate, getExpensesUsersPaginationDate,
        storeExpenseType, changeExpenseTypeStatus, deleteExpenseType, getExpenseTypes, getExpenseTypesPagination,
        storeSale,
        getCompaniesByOwner, storeOwner, changeOwnerStatus, deleteOwner, getOwners, getOwnersPagination,
        getReportSale, getReportLoan, getReportGeneral, getChartSale, getReportLoandate, getReportDaySale, getReportLogdate, getDetailReportGeneral, getReportLoanSale,
        getPaymentsMode,
        storeLoan, getLoansByCustomer, updateLoan, changeLoanStatus, getActiveLoanByCustomer, deleteFinalSale, deleteFinalSalePayment, getFinalSalePayment,
        storeFinalSale,
        storeQuote, getQuotesByLoans, deleteQuote, getBadQuotes,
        getBackup
    }
}

export default useApi;