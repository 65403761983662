import React,{useState,useEffect} from 'react';
import moment from 'moment'
import 'moment/locale/es'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Table,
    ListGroup,
    ListGroupItem
  } from "reactstrap";
  import Header from 'components/Headers/Header';

  import { useApi, useToast } from "../../../hooks";

  import { useSelector } from 'react-redux'
  import { getFilterUsers } from "../../../selectors/jobs";

  moment.locale('es')
  
const Log = () => {

    const company = useSelector(state => state.company.company)
    var date = moment().format('YYYY-MM-DD'); 
    const [startDate, setStartDate] = useState(date)
    const [endDate, setEndDate] = useState(date)
    const [userReport, setUserReport] = useState(null)
    const [logList, setLogList] = useState([])

    const [users, setUsers] = useState([])
    const toast = useToast();
    const { getAllUsers, getReportLogdate } = useApi();

    useEffect(()=>{
        getUserList();
    }, [])
    
    const getReportLogRange = async () =>{
        
        if(!userReport)
            return toast.error("Selecciona un usuario");
        const { response, error } = await getReportLogdate(startDate, endDate, userReport);
        if (response) {
            const { content, message } = response.data;
            setLogList(content)

        } else if (error) {
            error.message && toast.error(error.message);
        }
    
    }

    const getUserList = async () =>{
    
        const { response, error } = await getAllUsers();
        if (response) {
            const { content, message } = response.data;
            setUsers(content);
        } else if (error) {
            error.message && toast.error(error.message);
        }

    }

    const renderOptions = (list) => {
        var options = []
         list.map((prop,key) => {
             if(prop.rol_id != 2){
                 options.push(<option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>)
             }
            return <option key ={`${prop.id}_${prop.name}`} value={prop.id}>{prop.name}</option>
        });
        return options
    }

    const renderReportLogs = (quotes) => {
        let id = 0;
        let data = quotes.map((prop, key) => {
            id++;
            console.log(prop.created_at.substring(0,19))
            return  (

                <ListGroupItem
                    className="list-group-item-action flex-column align-items-start py-4 px-4"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    tag="a">

                        <div className="d-flex w-100 justify-content-between">
                            <div>
                                <h4 className="mt-3 mb-1"> {`${prop.user.name} - ${prop.action}`}</h4>
                            </div>
                            <small>{`${moment(prop.created_at.substring(0,19)+"Z").calendar()} - ${moment(prop.created_at.substring(0,19)+"Z").fromNow()}`}</small>
                        </div>
                       
                        {
                            prop.detail && prop.detail.includes('<thead>') ?
                            <> 
                                <Table className="align-items-center table-flush" responsive dangerouslySetInnerHTML={{ __html: prop.detail }}>
                                </Table>
                            </> :
                            <>
                                <p>
                                    {prop.detail}
                                </p>
                            </>
                        }
                        

                </ListGroupItem>
            )
        })
        return data;
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                                            Reporte De Préstamos del día
                                        </h6>
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="startDate">
                                                        Fecha Inicio
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="startDate"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={e=>{setStartDate(e.target.value)}}
                                                    type="date" >
                                                    </Input>
                                                            
                                                </FormGroup>
                                            </Col>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="endDate">
                                                        Fecha Fin
                                                    </label>
                                                    <Input 
                                                        name = "endDate"
                                                        className="form-control-alternative"
                                                        id="endDate"
                                                        value={endDate}
                                                        onChange={e=>{setEndDate(e.target.value)}}
                                                        type="date" />
                                                </FormGroup>
                                            </Col> 
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                    className="form-control-label"
                                                    htmlFor="userId"
                                                    >
                                                    Usuario
                                                    </label>
                                                    <Input
                                                    className="form-control-alternative"
                                                    id="userId"
                                                    name="userId"
                                                    placeholder="Seleccionar una opción"
                                                    type="select"
                                                    onChange={e=>setUserReport(e.target.value)}>
                                                        <option></option>
                                                        {renderOptions(users)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                                <Button className="my-4" color="primary" type="submit" onClick={()=>{getReportLogRange()}}>
                                                    Consultar
                                                </Button>
                                            </Col>                                   
                                        </Row>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="heading-small text-muted mb-4">
                                    Bitácora
                                </h6>
                                <ListGroup flush>
                                    {logList.length > 0 && renderReportLogs(logList)}
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
            
                </Row>
            </Container>
            
        </>
    );
};

export default Log;